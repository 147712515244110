let sortConfig = {
  key: null,
  direction: true,
};

const sortData = (data, setData, type, key) => {
  let sortedData = [...data];

  if (key === "vendor" || key === "customer") {
    sortedData.sort((a, b) => {
      const valueA = a[key]?.name.firstName + " " + a[key]?.name.lastName;
      const valueB = b[key]?.name.firstName + " " + b[key]?.name.lastName;

      return sortConfig.direction ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    });
  }

  if (type === "array") {
    sortedData.sort((a, b) => {
      const valueA = a.products.map((el) => el?.product?.productName || "").join(", ");
      const valueB = b.products.map((el) => el?.product?.productName || "").join(", ");

      return sortConfig.direction ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    });
  } else if (type === "string") {
    let valueA, valueB;

    sortedData.sort((a, b) => {
      if (key === "name") {
        valueA = a?.fullname || `${a?.name?.firstName} ${a?.name?.lastName}` || "";
        valueB = b?.fullname || `${b?.name?.firstName} ${b?.name?.lastName}` || "";
      } else if (key === "contact") {
        valueA = a?.contact?.phone || a?.phone || "";
        valueB = b?.contact?.phone || b?.phone || "";
      } else if (key === "email") {
        valueA = a?.contact?.email || a?.email || "";
        valueB = b?.contact?.email || b?.email || "";
      } else if (key === "address") {
        valueA = a?.address || `${a?.contact?.address?.street}, ${a?.contact?.address?.city}, ${a?.contact?.address?.country}` || "";
        valueB = b?.address || `${b?.contact?.address?.street}, ${b?.contact?.address?.city}, ${b?.contact?.address?.country}` || "";
      } else {
        valueA = String(a[key] || "");
        valueB = String(b[key] || "");
      }

      return sortConfig.direction ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    });
  } else if (type === "number") {
    let valueA, valueB;

    sortedData.sort((a, b) => {
      if (key === "balance") {
        valueA = a.balance.currentBalance || 0;
        valueB = b.balance.currentBalance || 0;
      } else {
        valueA = a[key] !== undefined ? a[key] : 0;
        valueB = b[key] !== undefined ? b[key] : 0;
      }

      return sortConfig.direction ? valueA - valueB : valueB - valueA;
    });
  } else if (type === "date") {
    sortedData.sort((a, b) => {
      const dateA = a[key] ? new Date(a[key]) : new Date(0);
      const dateB = b[key] ? new Date(b[key]) : new Date(0);

      return sortConfig.direction ? dateA - dateB : dateB - dateA;
    });
  }

  setData(sortedData);
  sortConfig = {
    key,
    direction: !sortConfig.direction,
  };
};

export default sortData;