import { useState } from "react";
import Cropper from "react-easy-crop";


export const ImageCropper = ({ image, onCropDone, onCropCancel }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);


    return (
        <div className="fixed left-1/2 -translate-x-1/2 -translate-y-1/2 size-[500px] z-20" style={{ top: "calc(50% - 48px)" }}>
            <div>
                <Cropper
                    image={image}
                    aspect={1 / 1}
                    crop={crop}
                    zoom={zoom}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={(_, px) => setCroppedArea(px)}
                    style={{
                        mediaStyle: {
                            height: "100%"
                        },
                        containerStyle: {
                            width: "500px",
                            height: "500px",
                            backgroundColor: "#fff",
                            transform: "translate(-50%, -50%)",
                            left: "50%",
                            top: "50%"
                        }
                    }}
                />
            </div>


            <div className="absolute -bottom-24 left-0 w-full p-4 bg-white space-y-2">
                <p className="text-red-500 cap-reg">Zoom to adjust*</p>
                <div className="flex gap-x-2">
                    <button className="btn btn-blue" onClick={() => onCropDone(croppedArea)}>Apply</button>
                    <button className="btn btn-outline" onClick={onCropCancel}>Cancel</button>
                </div>
            </div>
        </div>
    )
};