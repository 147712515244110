import React, { useState } from "react";
import axios from "axios";
import Draggable from "react-draggable";

import { Close, Loader, Navbar, Popup } from "../Components";
import throwError from "../utils/catch";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";


export const UpdateBalance = ({ onClose, isSalesPage = true, isUserPage = false }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);

  const isSmallScreen = window.innerWidth < 1023;


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    const formData = new FormData(e.target);
    const id = isUserPage ? formData.get("userId")?.trim() : formData.get(isSalesPage ? "saleId" : "purchaseId")?.trim();

    if (!id) {
      console.error("Error: Missing ID for update");
      setPopupData({
        isSuccess: false,
        message: "Error: Missing required ID"
      });
      setShowPopup(true);
      setLoader(false);
      return;
    }

    let payload = {
      amountPaid: Number(formData.get("amountPaid")),
      paymentDate: new Date(formData.get("paymentDate")),
      paymentMethod: formData.get("paymentMethod")
    };
    if (formData.get("comments")?.trim()) {
      payload.comments = formData.get("comments");
    }

    const model = isUserPage ? "users" : isSalesPage ? "sales" : "purchases";

    axios.put(`${apiUrl}/${model}/${id}/update-balance`, payload, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then((res) => {
      console.log(res.data);
      setShowPopup(true);
      setPopupData({
        isSuccess: true,
        message: res.data.message
      });
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    }).finally(() => setLoader(false));
  };

  const closePopup = () => {
    setShowPopup(false);
    onClose();
  };


  return (
    <div className="lg:py-4 fixed top-0 left-0 z-10 h-dvh w-full grid place-items-center overflow-y-auto bg-black bg-opacity-60 !mt-0">
      <Draggable disabled={isSmallScreen} handle=".drag-handle">
        <form className="max-lg:h-dvh w-full lg:w-[500px] lg:rounded-[--radius-main] bg-white z-10" onSubmit={handleSubmit}>
          <div className="lg:hidden"><Navbar /></div>

          <div className="drag-handle flex justify-between lg:bg-[--btn-primary-blue] lg:text-white px-6 lg:px-10 pt-4 lg:py-2 lg:rounded-t-[--radius-main]">
            <h3>Update Balance Form</h3>
            <div onClick={onClose} className="cursor-pointer hover:bg-[#ffffff0d] content-center">
              <Close fill="black" stroke="black" className="lg:hidden" />
              <Close fill="white" stroke="white" className="max-lg:hidden" />
            </div>
          </div>

          <div className="h-sm-form lg:max-h-80 overflow-y-auto">
            <div className="max-lg:mt-4 pb-4 px-6 lg:p-10 grid grid-cols-2 gap-y-3 gap-x-2 lg:gap-5 text-base font-semibold text-[--text-primary]">

              {/* Conditionally Render User ID, Sales ID and Purchase ID Field */}
              <div className="flex flex-col gap-y-2">
                <label htmlFor={isUserPage ? "userId" : isSalesPage ? "saleId" : "purchaseId"}>
                  {isUserPage ? "User" : isSalesPage ? "Sale" : "Purchase"} I.D*
                </label>
                <input type="text" placeholder="s1204" required
                  name={isUserPage ? "userId" : isSalesPage ? "saleId" : "purchaseId"}
                  id={isUserPage ? "userId" : isSalesPage ? "saleId" : "purchaseId"}
                />
              </div>

              <div className="flex flex-col gap-y-2">
                <label htmlFor="amountPaid">Amount Paid*</label>
                <input type="number" placeholder="10000" name="amountPaid" id="amountPaid" required />
              </div>

              <div className="flex flex-col gap-y-2">
                <label htmlFor="paymentDate">Payment Date*</label>
                <input type="date" name="paymentDate" id="paymentDate" required />
              </div>

              <div className="flex flex-col gap-y-2">
                <label htmlFor="paymentMethod">Payment Method*</label>
                <select name="paymentMethod" id="paymentMethod">
                  <option value="Cash">Cash</option>
                  <option value="Online">Online</option>
                </select>
              </div>

              <div className="flex flex-col gap-y-2 col-span-2">
                <label htmlFor="comments">Comments</label>
                <textarea name="comments" id="comments" placeholder="This amount was paid for..." rows={1} />
              </div>
            </div>
          </div>

          <div className="px-6 py-8 lg:p-10 flex gap-x-3 lg:gap-x-5 border-t border-[#AFB3B8]">
            <button type="submit" className="btn btn-blue">Update Balance</button>
            <button type="reset" className="btn btn-outline">Reset</button>
          </div>
        </form>
      </Draggable>



      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={closePopup} />}
      {loader && <Loader />}
    </div>
  );
};
