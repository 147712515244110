import img_account from "./account.svg"
import img_doubleArrow from "./doubleArrow.svg";
import img_edit from "./edit.svg";
import img_error from "./error.svg";
import img_hide from "./hide.svg";
import img_imsBg from "./ims-bg.png";
import img_imsLogo from "./ims-logo.png";
import img_itemsSold from "./items-sold.svg";
import img_logout from "./logoutUser.svg";
import img_navHamburger from "./nav-hamburger.svg";
import img_pen from "./pen.svg";
import img_print from "./print.svg"
import img_profileEdit from "./profile-edit.svg";
import img_profile from "./profile.png";
import img_search from "./search.svg";
import img_show from "./show.svg";
import img_skLogo from "./sk-logo.png";
import img_success from "./success.svg";
import img_tooltip from "./tooltip.svg";
import img_totalSale from "./total-sale.svg";
import img_totalStock from "./total-stock.svg";

export const
  account = img_account,
  doubleArrow = img_doubleArrow,
  edit = img_edit,
  error = img_error,
  hide = img_hide,
  imsBg = img_imsBg,
  imsLogo = img_imsLogo,
  itemsSold = img_itemsSold,
  logout = img_logout,
  navHamburger = img_navHamburger,
  pen = img_pen,
  print = img_print,
  profileEdit = img_profileEdit,
  profile = img_profile,
  search = img_search,
  show = img_show,
  skLogo = img_skLogo,
  success = img_success,
  tooltip = img_tooltip,
  totalSale = img_totalSale,
  totalStock = img_totalStock;