import React, { useEffect, useState } from "react";
import axios from "axios";
import Draggable from "react-draggable";

import { Close, Loader, Navbar, Popup } from "../Components";
import throwError from "../utils/catch";

const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api';


export const InventoryForm = ({ onClose, productToUpdate = null }) => {
  const [product, setProduct] = useState({
    productName: "",
    productCode: "",
    costPrice: null,
    sellingPrice: null,
    category: "",
    locationinWarehouse: "",
    stock: null,
    minimumStockLevel: null,
    maximumStockLevel: null,
    productDescription: "",
    comment: ""
  });

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);

  const isSmallScreen = window.innerWidth < 1023;


  useEffect(() => {
    if (productToUpdate) {
      setProduct({
        productName: productToUpdate?.productName,
        productCode: productToUpdate?.productCode,
        costPrice: productToUpdate?.costPrice,
        sellingPrice: productToUpdate?.sellingPrice,
        category: productToUpdate?.category,
        locationinWarehouse: productToUpdate?.locationinWarehouse || "",
        stock: productToUpdate?.stock || null,
        minimumStockLevel: productToUpdate?.minimumStockLevel || null,
        maximumStockLevel: productToUpdate?.maximumStockLevel || null,
        productDescription: productToUpdate?.productDescription || "",
        comment: productToUpdate?.comment
      });
    }
  }, [productToUpdate]);


  const handleSubmit = e => {
    e.preventDefault();
    setLoader(true);

    const method = productToUpdate ? "put" : "post";
    const url = productToUpdate ? `${apiUrl}/inventory/${productToUpdate._id}` : `${apiUrl}/inventory`;

    axios({
      method,
      url,
      data: product,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then(res => {
      console.log(res.data);
      onClose();
      resetForm();
    }).catch(err => {
      console.error(err);
      setShowPopup(true);
      throwError(err, setPopupData);
    }).finally(() => setLoader(false));
  }

  const resetForm = () => {
    setProduct({
      productName: "",
      productCode: "",
      costPrice: null,
      sellingPrice: null,
      category: "",
      locationinWarehouse: "",
      stock: null,
      minimumStockLevel: null,
      maximumStockLevel: null,
      productDescription: "",
      comment: "",
    });
  }


  return (
    <div className="lg:py-4 fixed top-0 left-0 z-10 h-dvh w-full grid place-items-center overflow-y-auto bg-black bg-opacity-60 !mt-0">
      <Draggable disabled={isSmallScreen} handle=".drag-handle">

        <form className="w-full lg:w-[940px] lg:rounded-[--radius-main] bg-white z-10 max-lg:h-dvh" onSubmit={handleSubmit}>
          <div className="lg:hidden">
            <Navbar />
          </div>

          <div className="drag-handle flex justify-between lg:bg-[--btn-primary-blue] lg:text-white px-6 lg:px-10 pt-4 lg:py-2 lg:rounded-t-[--radius-main]">
            <h3>{productToUpdate ? `Update Inventory - ${productToUpdate?.productCode}` : "Inventory Form"}</h3>
            <div onClick={onClose} className="cursor-pointer hover:bg-[#ffffff0d] content-center">
              <Close fill="black" stroke="black" className="lg:hidden" />
              <Close fill="white" stroke="white" className="max-lg:hidden" />
            </div>
          </div>

          <div className="lg:max-h-[600px] overflow-y-auto max-lg:mt-4 px-6 pb-4 lg:p-10 grid grid-cols-2 lg:grid-cols-4 gap-y-3 lg:gap-y-6 gap-x-2 lg:gap-x-5 text-base font-semibold h-sm-form">
            <div className="flex flex-col gap-y-1 lg:gap-y-2 max-lg:col-span-2">
              <label htmlFor="productName">Product Name*</label>
              <input type="text" placeholder="Galaxy Eco Solvent" required
                name="productName" id="productName" value={product.productName}
                onChange={(e) => setProduct({ ...product, productName: e.target.value })}
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="productCode">Product Code*</label>
              <input type="text" placeholder="INK1001" required
                name="productCode" id="productCode" value={product.productCode}
                onChange={(e) => setProduct({ ...product, productCode: e.target.value })}
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="costPrice">Cost Price*</label>
              <input type="number" placeholder="1000" required
                name="costPrice" id="costPrice" value={product.costPrice}
                onChange={(e) => setProduct({ ...product, costPrice: Number(e.target.value) })}
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="sellingPrice">Selling Price*</label>
              <input type="number" placeholder="1400" required
                name="sellingPrice" id="sellingPrice" value={product.sellingPrice}
                onChange={(e) => setProduct({ ...product, sellingPrice: Number(e.target.value) })}
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="category">Category*</label>
              <input type="text" placeholder="Printing Products" required
                value={product.category} name="category" id="category"
                onChange={(e) => setProduct({ ...product, category: e.target.value })}
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="location">Location in Warhouse</label>
              <input type="text" placeholder="Aisle 2, Shelf 3"
                value={product.locationinWarehouse} name="location" id="location"
                onChange={(e) => setProduct({ ...product, locationinWarehouse: e.target.value })}
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="stockQuantity">Quantity in Stock</label>
              <input type="number" placeholder="20"
                value={product.stock} name="stockQuantity" id="stockQuantity"
                onChange={(e) => setProduct({ ...product, stock: Number(e.target.value) })}
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="minStock">Min Stock Level</label>
              <input type="number" placeholder="10"
                value={product.minimumStockLevel} name="minStock" id="minStock"
                onChange={(e) => setProduct({ ...product, minimumStockLevel: Number(e.target.value) })}
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="maxStock">Max Stock Level</label>
              <input type="number" placeholder="100"
                value={product.maximumStockLevel} name="maxStock" id="maxStock"
                onChange={(e) => setProduct({ ...product, maximumStockLevel: Number(e.target.value) })}
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2 col-span-2 lg:col-span-3">
              <label htmlFor="productDesc">Product Description</label>
              <input type="text" placeholder="SK Standee"
                value={product.productDescription} name="productDesc" id="productDesc"
                onChange={(e) => setProduct({ ...product, productDescription: e.target.value })}
              />
            </div>

            <div className="flex flex-col col-span-2 lg:col-span-4 gap-y-1 lg:gap-y-2">
              <label htmlFor="comments">Comments</label>
              <textarea type="text" placeholder="Customer returned due to a defect in the material"
                value={product.comment} name="comments" id="comments"
                onChange={(e) => setProduct({ ...product, comment: e.target.value })}
              />
            </div>
          </div>

          <div className="px-6 py-8 lg:p-10 flex gap-x-3 lg:gap-x-5 border-t border-[#AFB3B8]">
            <button type="submit" className="btn btn-blue">
              {productToUpdate ? "Update Inventory" : "Add Entry"}
            </button>
            <button type="reset" className="btn btn-outline" onClick={resetForm}>Reset</button>
          </div>
        </form>
      </Draggable>


      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
      {loader && <Loader />}
    </div>
  );
};