import { useEffect, useState } from "react";
import axios from "axios";
import Draggable from "react-draggable";

import { Close, Loader, Navbar, Popup } from "../Components";
import throwError from "../utils/catch";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";

export const PurchaseDataForm = ({ onClose }) => {
  const [purchaseData, setPurchaseData] = useState({
    paymentMethod: "Cash",
    extraCharges: null,
    totalAmount: null,
    creditAmount: null,
    debitAmount: null,
    orderDate: "",
    paymentDate: "",
    salesTax: null,
    paymentStatus: "",
    comment: ""
  });
  const [products, setProducts] = useState([{ name: "", productCode: "", productQuantity: null, costPrice: null, totalCost: null }]);
  const [inventoryProducts, setInventoryProducts] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [vendor, setVendor] = useState();

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);

  const isSmallScreen = window.innerWidth < 1023;


  useEffect(() => {
    getInventoryProducts();
    getVendors()
  }, []);

  // Calculate total amount (sum of total cost of all products + extra charges)
  useEffect(() => {
    const totalAmount = products.reduce((total, item) => total + (item.totalCost || 0), 0) + Number(purchaseData.extraCharges || 0);
    setPurchaseData((prev) => ({ ...prev, totalAmount }));
  }, [products, purchaseData.extraCharges]);

  // Calculate debit amount (total - credit)
  useEffect(() => {
    const debitAmount = (purchaseData.totalAmount || 0) - (purchaseData.creditAmount || 0);
    setPurchaseData((prev) => ({ ...prev, debitAmount }));
  }, [purchaseData.totalAmount, purchaseData.creditAmount]);


  const getInventoryProducts = () => {
    axios.get(`${apiUrl}/inventory`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then((res) => {
      setInventoryProducts(res.data.inventory);
      setProducts([{
        product: res.data.inventory[0],
        productQuantity: "",
        totalCost: "",
      }]);
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    });
  };

  const getVendors = () => {
    axios.get(`${apiUrl}/users?type=vendor&isActive=true`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then((res) => {
      console.log(res.data.message);
      setVendors(res.data.users);
      setVendor(res.data.users[0]._id);
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    });
  }

  const handleChange = (index, field, value) => {
    if (field === "product") value = JSON.parse(value);
    const newItems = [...products];
    newItems[index][field] = value;

    // Total Cost Calculation
    if (field === "productQuantity" || field === "costPrice") {
      const quantity = newItems[index].productQuantity || 0;
      const price = newItems[index].costPrice || 0;
      newItems[index].totalCost = quantity * price;
    }

    setProducts(newItems);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    axios.post(`${apiUrl}/purchases`, {
      ...purchaseData, vendor, products
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then((res) => {
      console.log(res);
      onClose();
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    }).finally(() => setLoader(false));
  };

  const handleReset = () => {
    setPurchaseData({
      paymentMethod: "Cash",
      extraCharges: null,
      totalAmount: null,
      creditAmount: null,
      debitAmount: null,
      orderDate: "",
      paymentDate: "",
      salesTax: null,
      paymentStatus: "",
      comment: ""
    });
    setProducts([{ name: "", productCode: "", productQuantity: null, costPrice: null, totalCost: null }]);
  };

  const addItem = () => setProducts([...products, { name: "", productCode: "", productQuantity: null, costPrice: null, totalCost: null }]);
  const removeItem = (index) => products.length > 1 && setProducts(products.filter((_, i) => i !== index));


  return (
    <div className="lg:py-4 fixed top-0 left-0 z-10 h-dvh w-full grid place-items-center overflow-y-auto bg-black bg-opacity-60 !mt-0">
      <Draggable disabled={isSmallScreen} handle=".drag-handle">
        <form className="w-full lg:w-[940px] lg:rounded-[--radius-main] bg-white z-10 max-lg:h-dvh" onSubmit={handleSubmit}>
          <div className="lg:hidden"> <Navbar /> </div>

          <div className="drag-handle flex justify-between lg:bg-[--btn-primary-blue] lg:text-white px-6 lg:px-10 pt-4 lg:py-2 lg:rounded-t-[--radius-main]">
            <h3>Purchase Data Form</h3>
            <div onClick={onClose} className="cursor-pointer hover:bg-[#ffffff0d] content-center">
              <Close fill="black" stroke="black" className="lg:hidden" />
              <Close fill="white" stroke="white" className="max-lg:hidden" />
            </div>
          </div>

          <div className="lg:max-h-[600px] overflow-y-auto max-lg:mt-4 px-6 pb-4 lg:p-10 space-y-4 lg:space-y-6 h-sm-form">
            <div className="bg-[--primary-gray] grid grid-cols-1 gap-y-6 p-4 lg:px-5 lg:py-[18px] rounded-md">
              <div className="flex lg:justify-end max-lg:order-1">
                <button className="btn btn-white" onClick={addItem}> + Add Item </button>
              </div>

              {products.map((item, i) => (
                <div
                  key={i}
                  className={`grid grid-cols-2 lg:flex gap-y-3 gap-x-2 lg:gap-x-5 items-end text-base font-semibold max-lg:pb-4
                    ${i > 0 && "max-lg:border-t max-lg:border-[#848F99] max-lg:pt-2.5"}
                    `}
                >
                  <div className="flex flex-col gap-y-1 lg:gap-y-2 lg:w-[266px] max-lg:col-span-2 lg:grow">
                    <label htmlFor={`product${i}`}>Product*</label>
                    <select name={`product${i}`} id={`product${i}`} onChange={(e) => handleChange(i, "product", e.target.value)}>
                      {inventoryProducts.map((inventory) => (
                        <option key={inventory._id} value={JSON.stringify(inventory)}>
                          {inventory.productName} - Code:
                          {inventory.productCode}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex flex-col gap-y-2 lg:w-[140px]">
                    <label htmlFor={`productQuantity${i}`}> Product Quantity* </label>
                    <input type="number" placeholder="10" name={`productQuantity${i}`} id={`productQuantity${i}`} required
                      value={item.productQuantity} onChange={(e) => handleChange(i, "productQuantity", e.target.value)}
                    />
                  </div>

                  {/* Cost Price Field */}
                  <div className="flex flex-col gap-y-2 lg:w-[140px]">
                    <label htmlFor={`costPrice${i}`}>Cost Price*</label>
                    <input type="number" placeholder="2500" name={`costPrice${i}`} id={`costPrice${i}`} required
                      value={item.costPrice} onChange={(e) => handleChange(i, "costPrice", e.target.value)}
                    />
                  </div>

                  <div className="flex flex-col gap-y-2 max-lg:col-span-2 lg:w-[140px]">
                    <label htmlFor={`totalCost${i}`}>Total Cost*</label>
                    <input type="number" placeholder="10" name={`totalCost${i}`} id={`totalCost${i}`} required
                      value={item.totalCost} onChange={(e) => handleChange(i, "totalCost", Number(e.target.value))}
                    />
                  </div>

                  <div className="cursor-pointer pb-2 max-lg:-order-1 max-lg:col-span-2 max-lg:justify-self-end" onClick={() => removeItem(i)}>
                    <Close fill="red" stroke="red" />
                  </div>
                </div>
              ))}
            </div>

            <div className="grid grid-cols-2 lg:grid-cols-4 gap-y-6 gap-x-2 lg:gap-x-5 text-base font-semibold text-[--text-primary]">
              <div className="flex flex-col col-span-2 gap-y-1 lg:gap-y-2">
                <label htmlFor="vendor">Vendor*</label>
                <select name="vendor" id="vendor" onChange={(e) => setVendor(e.target.value)}>
                  {vendors?.map((ven) => (
                    <option key={ven._id} value={ven._id}>
                      {ven.name.firstName} {ven.name.lastName} - {ven.userId}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="purchaseDate">Purchase Date*</label>
                <input type="date" name="purchaseDate" id="purchaseDate" required
                  value={purchaseData.purchaseDate} onChange={(e) => setPurchaseData({ ...purchaseData, purchaseDate: e.target.value })} />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="extraCharges">Extra Charges</label>
                <input type="number" placeholder="1000" name="extraCharges" id="extraCharges"
                  value={purchaseData.extraCharges} onChange={(e) => setPurchaseData({ ...purchaseData, extraCharges: e.target.value })} />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2 col-span-2">
                <label htmlFor="totalAmount">Total Amount*</label>
                <input type="number" placeholder="10000" name="totalAmount" id="totalAmount" required value={purchaseData.totalAmount} readOnly />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="creditAmount">Debit Amount*</label>
                <input type="number" placeholder="10000" name="creditAmount" id="creditAmount" required
                  value={purchaseData.creditAmount} onChange={(e) => setPurchaseData({ ...purchaseData, creditAmount: e.target.value })} />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="debitAmount">Credit Amount*</label>
                <input type="number" placeholder="10000" name="debitAmount" id="debitAmount" required value={purchaseData.debitAmount} readOnly />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="paymentMethod">Payment Method*</label>
                <select name="paymentMethod" id="paymentMethod" required
                  value={purchaseData.paymentMethod} onChange={(e) => setPurchaseData({ ...purchaseData, paymentMethod: e.target.value })}>
                  <option>Cash</option>
                  <option>Online</option>
                </select>
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="orderDate">Order Date</label>
                <input type="date" name="orderDate" id="orderDate"
                  value={purchaseData.orderDate} onChange={(e) => setPurchaseData({ ...purchaseData, orderDate: e.target.value })} />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="paymentDate">Payment Date</label>
                <input type="date" name="paymentDate" id="paymentDate"
                  value={purchaseData.paymentDate} onChange={(e) => setPurchaseData({ ...purchaseData, paymentDate: e.target.value })} />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="salesTax">Sales Tax (%)</label>
                <input type="number" placeholder="5" name="salesTax" id="salesTax" min="0" max="100"
                  value={purchaseData.salesTax} onChange={(e) => setPurchaseData({ ...purchaseData, salesTax: e.target.value })} />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2 col-span-2 lg:col-span-4">
                <label htmlFor="comments">Comments</label>
                <textarea type="text" placeholder="We sold this item in less amount than purchase" name="comments" id="comments"
                  value={purchaseData.comment} onChange={(e) => setPurchaseData({ ...purchaseData, comment: e.target.value })} />
              </div>
            </div>
          </div>

          <div className="px-6 py-8 lg:p-10 flex gap-x-3 lg:gap-x-5 border-t border-[#AFB3B8]">
            <button type="submit" className="btn btn-blue">Add Entry</button>
            <button type="reset" className="btn btn-outline" onClick={handleReset}>Reset</button>
          </div>
        </form>
      </Draggable>

      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
      {loader && <Loader />}
    </div>
  );
};