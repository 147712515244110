import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { ProfilePopup } from "../Components";

import { imsLogo, navHamburger } from "../assets";
import { useAuth } from "../context/AuthContext";


export const Navbar = () => {
  const [showUserProfilePopup, setShowUserProfilePopup] = useState(false);
  const [showNav, setShowNav] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    if (showNav) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [showNav]);

  return (
    <nav className="w-full nav-shadow z-10">
      <div className="center py-5 px-6 lg:px-[60px] lg:py-[--radius-main] flex justify-between items-center">
        <Link to="/"><img src={imsLogo} alt="sk-logo" /></Link>

        <div
          className={`max-lg:absolute top-[72px] left-0 w-full bg-white max-lg:h-[100dvh] z-10 max-lg:px-6 max-lg:py-4 flex max-lg:flex-col lg:items-center lg:justify-center gap-6 max-lg:border-t text-[--text-primary] pl-bold ${!showNav && "max-lg:hidden"
            }`}
        >
          <NavLink to="/" title="Go to Dashboard" aria-label="Go to Dashboard"
            className={({ isActive }) => `hover:text-[--btn-primary-blue] max-lg:w-fit ${isActive && "half-underline"}`}
          > Dashboard </NavLink>

          <NavLink to="/sales" title="Go to Sales" aria-label="Go to Sales"
            className={({ isActive }) => `hover:text-[--btn-primary-blue] max-lg:w-fit ${isActive && "half-underline"}`}
          > Sales </NavLink>

          <NavLink to="/inventory" title="Go to Inventory" aria-label="Go to Inventory"
            className={({ isActive }) => `hover:text-[--btn-primary-blue] max-lg:w-fit ${isActive && "half-underline"}`}
          > Inventory </NavLink>

          <NavLink to="/purchase" title="Go to Purchase" aria-label="Go to Purchase"
            className={({ isActive }) => `hover:text-[--btn-primary-blue] max-lg:w-fit ${isActive && "half-underline"}`}
          > Purchase </NavLink>

          <NavLink to="/users" title="Go to User" aria-label="Go to User"
            className={({ isActive }) => `hover:text-[--btn-primary-blue] max-lg:w-fit ${isActive && "half-underline"}`}
          > User </NavLink>

          <NavLink to="/sales-analysis" title="Go to Sales analysis" aria-label="Go to Sales analysis"
            className={({ isActive }) => `hover:text-[--btn-primary-blue] max-lg:w-fit ${isActive && "half-underline"}`}
          > Sales Analysis </NavLink>
        </div>

        <div className="lg:relative max-lg:flex gap-x-2 rounded-full">
          {user?.image ?
            <img
              src={user?.image}
              alt="sk-profile"
              onClick={() => setShowUserProfilePopup(true)}
              className={`cursor-pointer size-6 lg:size-10 rounded-full ${showUserProfilePopup && "relative z-10"} ${showNav && "max-lg:hidden"}`}
            />
            : <h2
              className="bg-[--btn-primary-blue] text-white text-xl size-10 rounded-full content-center text-center cursor-pointer"
              onClick={() => setShowUserProfilePopup(true)}
            >
              {user?.name?.firstName?.at(0)}
            </h2>
          }
          {showUserProfilePopup && <ProfilePopup closePopup={() => setShowUserProfilePopup(false)} />}

          <img src={navHamburger} alt="Hamburger" onClick={() => setShowNav(!showNav)} className="lg:hidden" />
        </div>
      </div>
    </nav>
  );
};