import { useEffect, useState } from "react";

import axios from "axios";
import { utils, write } from "xlsx";
import { saveAs } from "file-saver";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { Navbar, Footer, Popup, Loader, ContentLoader } from "../Components";
import { doubleArrow, search, itemsSold as itemsSoldIcon, totalSale as totalSaleIcon, totalStock as totalStockIcon } from "../assets";
import formatDate from "../utils/formatDate";
import throwError from "../utils/catch";
import sortData from "../utils/sortData";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";


const chart = {
  title: "",
  chart: {
    height: 270,
    type: "spline",
  },
  series: [
    {
      name: "Total Stock",
      data: [1, 3, 2, 4, 16, 20, 23, 25, 20, 21, 27, 30],
      type: "spline",
      color: "#afcde7",
      animation: true,
      marker: { radius: 0 },
      lineWidth: 1,
    },
    {
      name: "Item sold",
      data: [1, 3, 6, 7, 5, 8, 11, 14, 15, 19, 24, 27],
      type: "spline",
      color: "#25aae1",
      animation: true,
      marker: { radius: 0 },
      lineWidth: 1,
    },
    {
      name: "Total Sales",
      data: [2, 5, 8, 3, 28, 35, 37, 40, 13, 50, 19, 17],
      type: "spline",
      color: "#606fca",
      animation: true,
      marker: { radius: 0 },
      lineWidth: 1,
    },
  ],
  xAxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  },
  yAxis: { title: "" },
  credits: { enabled: false },
};


export const Dashboard = () => {
  const [sales, setSales] = useState([]);
  const [totalStock, setTotalStock] = useState(0);
  const [itemSold, setItemSold] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);
  const [contentLoader, setContentLoader] = useState(false);



  useEffect(() => {
    getSales();
    getTotalStock();
  }, []);


  const getTotalStock = () => {
    axios.get(`${apiUrl}/inventory/dashboard`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then((res) => {
      setTotalStock(res.data.inventory.reduce((acc, data) => data.stock + acc, 0));
    }).catch((err) => {
      console.error(err);
      if (err.response.status === 404) return;
      throwError(err, setPopupData);
      setShowPopup(true);
    });
  };

  const getSales = () => {
    setContentLoader(true);

    // Last week sales
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    let startDate = new Date(Date.now() - oneWeek).toISOString();
    let endDate = new Date(Date.now()).toISOString();

    axios.get(`${apiUrl}/sales/dashboard?startDate=${startDate}&endDate=${endDate}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then((res) => {
      setSales(res.data.sales);
      console.log(res.data.message);

      // Sales data in cards
      const quantity = res.data.sales.reduce((total, sale) => total + sale.products.reduce((acc, item) => acc + item.productQuantity, 0), 0);
      setItemSold(quantity);

      const profit = res.data.sales.reduce((acc, item) => acc + item.saleAmount, 0);
      setTotalSales(profit);
    }).catch((err) => {
      console.error(err);

      if (err.response.status === 404) err.response.data.message = "No sales found in the last week";
      throwError(err, setPopupData);
      setSales([]);
    }).finally(() => setContentLoader(false));
  };

  const exportData = (e) => {
    e.preventDefault();
    setLoader(true);

    const form = new FormData(e.target);

    const startDate = form.get("startDate");
    const endDate = form.get("endDate");
    const recordType = form.get("recordType");

    const startDateObj = new Date(startDate).toISOString();
    const endDateObj = new Date(endDate).toISOString();


    if (startDateObj > endDateObj) {
      setShowPopup(true);
      setPopupData({
        isSuccess: false,
        message: "The start date must be earlier than the end date",
      });
      return;
    }

    axios.get(`${apiUrl}/${recordType}/export-data?startDate=${startDateObj}&endDate=${endDateObj}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then((res) => {
      // Modifying data from DB format to purely human-readable
      console.log(res.data.message);

      const data = res.data[recordType].map(({ __v, _id, transactions, createdAt, updatedAt, ...rest }) => {
        Object.keys(rest).forEach((key) => {
          // Products Array
          if (Array.isArray(rest[key])) {
            rest[key] = rest[key].map(el => ({
              productName: el?.product?.productName || "Product Deleted",
              sellingPrice: el?.sellingPrice || el?.costPrice,
              productQuantity: el.productQuantity,
              totalCost: el.totalCost
            })).map((obj, i) => (
              `${obj.productName} - Rs. ${obj.sellingPrice} x ${obj.productQuantity}qty = Rs. ${obj.totalCost}${i < rest[key].length - 1 ? ", " : ""}`
            )).join("\n");
          }

          // Date fields
          if (key.endsWith("Date")) {
            rest[key] = rest[key] && formatDate(rest[key]);
          }

          // Customer, Vendor, SalesPerson, CreatedBy
          if (key === "customer" || key === "vendor") {
            rest[key] = `${rest[key].name.firstName} ${rest[key].name.lastName} - ${rest[key].userId}`;
          } else if (key === "salesPerson" || key === "createdBy") {
            rest[key] = `${rest[key].name.firstName} ${rest[key].name.lastName} - @${rest[key].username}`;
          }

          const header = key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (match) => match.toUpperCase());
          rest[header] = rest[key];
          delete rest[key];
        });

        return rest;
      });

      // Generating Excel
      const worksheet = utils.json_to_sheet(data);
      const workbook = utils.book_new();
      const sheetName = recordType.charAt(0).toUpperCase() + recordType.slice(1);
      utils.book_append_sheet(workbook, worksheet, sheetName);

      // Excel Buffer
      const excelBuffer = write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Set Excel File type
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Save Excel file with timestamp
      const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
      saveAs(blob, `${sheetName}_${timestamp}.xlsx`);
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    }).finally(() => setLoader(false));
  };

  const filteredSales = sales ?
    sales.filter((sale) => {
      return sale.products
        .map((product) => product?.product?.productName || "")
        .join(", ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    }) :
    [];


  return (
    <>
      {/* Navbar */}
      <Navbar />



      <main className="py-4 lg:py-8 px-6 lg:px-[60px] center space-y-8 flex-grow">

        {/* Cards & Charts */}
        <section className="grid lg:grid-cols-2 gap-y-4 gap-x-5">
          <div className="space-y-4 lg:space-y-5">
            <form className="grid grid-cols-2 lg:items-end gap-4 gap-x-3 max-lg:text-xs" onSubmit={exportData}>
              <div className="flex flex-col gap-y-1 lg:gap-y-2 max-lg:col-span-2">
                <label htmlFor="recordType" className="font-bold">Record Type</label>
                <select name="recordType" id="recordType" required>
                  <option value="sales">Sales</option>
                  <option value="purchases">Purchases</option>
                </select>
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="startDate" className="font-bold">Start Date</label>
                <input type="date" name="startDate" id="startDate" required className="w-full" />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="endDate" className="font-bold">End Date</label>
                <input type="date" name="endDate" id="endDate" required className="w-full" />
              </div>

              <button type="submit" className="btn btn-blue w-full max-lg:col-span-2">Export</button>
            </form>

            <div className="flex gap-x-2 lg:gap-x-5 *:bg-[--primary-gray] *:lg:space-y-3 *:p-2.5 *:lg:px-4 *:lg:py-8 *:rounded-[--radius-main] *:grow">
              <div>
                <div className="lg:flex gap-x-3 space-y-2">
                  <img src={totalStockIcon} alt="Stock" />
                  <div className="text-base uppercase">Total Stock</div>
                </div>
                <div className="lg:text-3xl font-semibold">{totalStock}</div>
              </div>

              <div>
                <div className="lg:flex gap-x-3 space-y-2">
                  <img src={itemsSoldIcon} alt="Sales" />
                  <div className="text-base uppercase">Items Sold</div>
                </div>
                <div className="lg:text-3xl font-semibold">{itemSold}</div>
              </div>

              <div>
                <div className="lg:flex gap-x-3 space-y-2">
                  <img src={totalSaleIcon} alt="totalSales" />
                  <div className="text-base uppercase">Total Sales</div>
                </div>
                <div className="lg:text-3xl font-semibold">{totalSales}</div>
              </div>
            </div>
          </div>

          <div className="border border-[#848F99] rounded-[--radius-main] p-5">
            <HighchartsReact highcharts={Highcharts} options={chart} />
          </div>
        </section>




        {/* Table */}
        <section>
          <div className="bg-[--btn-primary-blue] px-4 lg:px-10 py-2 rounded-t-[--radius-main]">
            <div className="relative flex justify-end">
              <input type="text" placeholder="Search Item" className="rounded-[22px] pl-reg lg:pr-10" value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} />

              <img
                src={search}
                alt="search"
                className="absolute right-4 top-1/2 -translate-y-1/2"
              />
            </div>
          </div>

          <div className="overflow-x-auto lg:max-h-[480px]">
            <table className="w-full">
              <thead>
                <tr>
                  <th id="salesId">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(sales, setSales, "number", "saleId")}
                    >
                      <span>Sales ID</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="customer">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(sales, setSales, "string", "customer")}
                    >
                      <span>Customer</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="item">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(sales, setSales, "array")}
                    >
                      <span>Item</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="payMethod">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer text-nowrap w-max"
                      onClick={() => sortData(sales, setSales, "string", "paymentMethod")}
                    >
                      <span>Payment Method</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="saleAmount">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(sales, setSales, "number", "saleAmount")}
                    >
                      <span>Sale Amount</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="creditAmount">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer text-nowrap w-max"
                      onClick={() => sortData(sales, setSales, "number", "creditAmount")}
                    >
                      <span>Credit Amount</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="debitAmount">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer text-nowrap w-max"
                      onClick={() => sortData(sales, setSales, "number", "debitAmount")}
                    >
                      <span>Debit Amount</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="saleDate">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(sales, setSales, "date", "saleDate")}
                    >
                      <span>Sale Date</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="actions" />
                </tr>
              </thead>

              <tbody>
                {
                  contentLoader ? (
                    <tr><ContentLoader columns={8} /></tr>
                  ) : filteredSales.length > 0 ? (
                    filteredSales.map((sale) => (
                      <tr key={sale._id} className="*:px-4 *:py-2 h-11 ps-reg">

                        <td headers="salesId">
                          {sale.saleId < 100 ?
                            sale.saleId < 10 ? "00" + sale.saleId : "0" + sale.saleId
                            : sale.saleId}
                        </td>

                        <td headers="customer">{`${sale.customer.name.firstName} ${sale.customer.name.lastName} - ${sale.customer.userId}`}</td>

                        <td headers="item">
                          <div>
                            {sale.products.map((el, index) => (
                              <div key={el._id || index} className="w-max">
                                {el.product ?
                                  `${el?.product?.productName} - ${el?.productQuantity} qty - Rs. ${el?.totalCost}`
                                  :
                                  `Deleted Product - ${el.productQuantity} qty - Rs. ${el.totalCost}`
                                }
                              </div>
                            ))}
                          </div>
                        </td>


                        <td headers="payMethod">{sale.paymentMethod}</td>


                        {/* Sale Amount */}
                        <td headers="saleAmount">{sale.saleAmount}</td>


                        {/* Credit Amount */}
                        <td headers="creditAmount">{sale.creditAmount}</td>

                        <td headers="debitAmount">{sale.debitAmount}</td>


                        {/* Sale Date */}
                        <td headers="saleDate">{formatDate(sale.saleDate)}</td>

                      </tr>

                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-[#E83535] font-bold p-4"> {popupData.message} </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </section>
      </main>



      {/* Footer */}
      <Footer />


      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
      {loader && <Loader />}
    </>
  );
};