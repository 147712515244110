export const Footer = () => {

  return (
    <footer className="bg-[--btn-primary-blue]">
      <div className='text-white max-md:flex-col max-md:gap-y-2 flex justify-between ps-reg text-center p-2 lg:px-[60px] lg:py-4 center'>
        <p>Copyright © 2025 IMS - SK Printing Solution - All Rights Reserved.</p>
        <p>Design & Developed by <a href="https://techieonix.io/" className='font-bold'>Techieonix International</a> | <a href="mailto:contact@techieonix.com">contact@techieonix.com</a></p>
      </div>
    </footer>
  );
};