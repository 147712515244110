import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";

import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './Components/ProtectedRoute';
import { Login, Products, Dashboard, Sales, Inventory, Purchase, Users, SalesAnalysis, ForgotPassword, ResetPassword, ProfilePage, Accounts } from "./Pages";


function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/user/reset-password/:token" element={<ResetPassword />} />

                    {/* Protected Routes */}
                    <Route element={<ProtectedRoute />}>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/sales" element={<Sales />} />
                        <Route path="/inventory" element={<Inventory />} />
                        <Route path="/sales-analysis" element={<SalesAnalysis />} />
                        <Route path="profile-page" element={<ProfilePage />} />
                        <Route path="/purchase" element={<Purchase />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/accounts" element={<Accounts />} />
                    </Route>
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;