import React, { useEffect, useRef, useState } from "react";

import Draggable from "react-draggable";
import axios from "axios";
import ReactToPrint from "react-to-print";

import { doubleArrow, skLogo, print } from "../assets";
import { Navbar, Loader, Popup, UpdateBalance, Close } from "../Components";
import formatDate from "../utils/formatDate";
import sortData from "../utils/sortData";
import throwError from "../utils/catch";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";


export const UserProfile = ({ onClose, userId }) => {
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [updateBalanceForm, setUpdateBalanceForm] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);

  const [startDate, setStartDate] = useState(new Date(Date.now() - (1000 * 60 * 60 * 24 * 7)));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const printRef = useRef();

  const isSmallScreen = window.innerWidth < 1023;

  useEffect(() => getUser(userId), [userId]);


  const getUser = (id, start, end) => {
    setLoader(true);

    axios.get(`${apiUrl}/users/${id}?startDate=${start || startDate}&endDate=${end || endDate}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then((res) => {
      console.log(res.data.message);

      setUser(res.data.user);
      setUserName(`${res.data.user?.name?.firstName.at(0).toUpperCase() + res.data.user?.name?.firstName.slice(1) || ""} ${res.data.user?.name?.lastName.at(0).toUpperCase() + res.data.user?.name?.lastName.slice(1) || ""}`);

      const updatedTranscations = getFormattedTransactions(res.data.user);
      setTransactions(updatedTranscations);
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    }).finally(() => setLoader(false));
  };

  const handleDateFilter = (e) => {
    e.preventDefault();

    const start = new Date(e.target[0].value);
    const end = new Date(e.target[1].value);

    // Validating Start and End dates
    if (start.getTime() > end.getTime()) {
      setPopupData({
        isSuccess: false,
        message: "Start date must be before end date"
      });
      setShowPopup(true);
      return;
    }

    setStartDate(start);
    setEndDate(end);

    getUser(userId, start, end);
  }

  const closeForm = () => {
    getUser(userId);
    setUpdateBalanceForm(false);
  };

  function getFormattedTransactions(data) {
    let transactions = [];
    const purpose = data.type === "Customer" ? "sales" : "purchases";

    // Extract transactions from sales
    data[purpose] && data[purpose].forEach(entry => {
      let balance = entry?.saleAmount || entry?.totalAmount;
      entry.transactions && entry?.transactions.forEach(transaction => {
        balance -= transaction.amount; // Update balance
        let description = entry.products.map(p => `${p.product.productName} (${p.product.productCode}) - Rs. ${p.sellingPrice || p.costPrice} x ${p.productQuantity} = Rs. ${p.totalCost}`) || transaction.type;
        entry.extraCharges && description.push(`Extra Charges: Rs. ${entry.extraCharges}`);
        transactions.push({
          date: formatDate(transaction.date),
          ref: transaction.transactionId,
          description,
          dueDate: entry.paymentDueDate ? formatDate(entry.paymentDueDate) : '-',
          amount: transaction.amount,
          balance
        });
      });
    });

    // Extract standalone transactions
    data.transactions && data.transactions.forEach((transaction, i) => {
      transactions.push({
        date: formatDate(transaction.date),
        ref: transaction.transactionId,
        description: transaction.type,
        dueDate: '-',
        amount: transaction.amount,
        balance: i === data.transactions.length - 1 ? data.balance.currentBalance : '-'
      });
    });

    return transactions;
  }


  return (
    <div className="lg:py-4 fixed top-0 left-0 z-10 h-dvh w-full grid place-items-center overflow-y-auto bg-black bg-opacity-60 !mt-0">
      <Draggable disabled={isSmallScreen} handle=".drag-handle">

        <div className="w-full lg:w-[1000px] lg:rounded-[--radius-main] bg-white z-10 max-lg:h-dvh max-lg:overflow-y-auto">
          <div className="lg:hidden"> <Navbar /> </div>

          <div className="drag-handle flex justify-between lg:bg-[--btn-primary-blue] lg:text-white px-6 lg:px-10 pt-4 lg:py-2 lg:rounded-t-[--radius-main]">
            <h3>{userName}</h3>
            <div onClick={onClose} className="cursor-pointer hover:bg-[#ffffff0d] content-center">
              <Close fill="black" stroke="black" className="lg:hidden" />
              <Close fill="white" stroke="white" className="max-lg:hidden" />
            </div>
          </div>



          <section className="px-[25px] py-4 lg:p-10 space-y-5 lg:space-y-10">
            <div className="bg-[--primary-gray] p-5 rounded-2xl space-y-3">
              <div className="space-y-3">
                <h2>{userName}</h2>

                <div className="space-y-1">
                  <p className="pl-bold">User ID: {`${user?.userId}`}</p>
                  <p>User type: {`${user?.type}`}</p>
                  <p>Email: {`${user?.contact.email}`}</p>
                  <p>Mobile: {`${user?.contact.phone}`}</p>
                  <p>Address: {`${user?.contact.address.street} ${user?.contact.address.city}, ${user?.contact.address.country}`}</p>
                  <p>Registration date: {`${formatDate(user?.registrationDate)}`}</p>
                </div>
              </div>
            </div>



            {/* Summary */}
            <div className="max-lg:space-y-3 lg:flex items-center gap-x-9 border border-black p-5 rounded-[--radius-main]">
              <p className="pl-bold">Account Summary:</p>
              <div className="lg:flex gap-x-5">
                <div className="text-sm">
                  Total Purchase:
                  <h3 className="inline"> {user?.balance?.totalPurchase} </h3>
                </div>
                <div className="text-sm">
                  Total Paid Amount:
                  <h3 className="inline"> {user?.balance?.totalAmountPaid} </h3>
                </div>
                <div className="text-sm">
                  Total Outstanding:
                  <h3 className="inline"> {user?.balance?.currentBalance} </h3>
                </div>
              </div>
            </div>



            {/* Date filter */}
            <form className="max-lg:space-y-3 lg:flex justify-start items-end gap-x-4 font-semibold" onSubmit={handleDateFilter}>
              <div className="flex flex-col gap-y-1 lg:gap-y-2 lg:w-[200px] shrink-0">
                <label htmlFor="startDate">Start Date</label>
                <input type="date" name="startDate" id="startDate" />
              </div>
              <div className="flex flex-col gap-y-1 lg:gap-y-2 lg:w-[200px] shrink-0">
                <label htmlFor="endDate">End Date</label>
                <input type="date" name="endDate" id="endDate" />
              </div>
              <button type="submit" className="btn btn-blue max-lg:w-full">
                Search
              </button>
            </form>



            {/* Transactions */}
            <div className="space-y-5">
              <div className="max-lg:space-y-3 lg:flex justify-between items-center">
                <p><span className="font-bold">From</span> {formatDate(startDate)} <span className="font-bold">To</span> {formatDate(endDate)}</p>

                <div className="flex gap-x-3 justify-end">
                  {user?.balance?.currentBalance > 0 &&
                    <button
                      className="btn btn-blue max-lg:w-full"
                      onClick={() => setUpdateBalanceForm(true)}
                    >
                      Balance Update
                    </button>
                  }

                  {transactions.length > 0 &&
                    <ReactToPrint
                      trigger={() => <button
                        className="btn btn-outline text-[--text-primary] flex items-center justify-center gap-x-2.5 max-lg:w-full"
                      >
                        <img src={print} alt="Print Button" />
                        Print Memo
                      </button>
                      }
                      content={() => printRef.current}
                    />
                  }
                </div>
              </div>


              <div className="overflow-auto h-sm-balance-inquiry lg:max-h-64 border border-[#00000033]">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th id="date">
                        <div
                          className="flex justify-between px-4 py-2 cursor-pointer w-max"
                          onClick={() => sortData(transactions, setTransactions, "date", "date")

                          }
                        >
                          <span>Date</span>
                          <img src={doubleArrow} alt="Sort Icon" />
                        </div>
                      </th>

                      <th id="ref">
                        <div
                          className="flex justify-between px-4 py-2 cursor-pointer w-max"
                          onClick={() => sortData(transactions, setTransactions, "string", "ref")

                          }
                        >
                          <span>Ref #</span>
                          <img src={doubleArrow} alt="Sort Icon" />
                        </div>
                      </th>

                      <th id="description">
                        <div className="flex justify-between px-4 py-2 cursor-pointer w-max"
                          onClick={() => sortData(transactions, setTransactions, "string", "description")}
                        >
                          <span>Description</span>
                          <img src={doubleArrow} alt="Sort Icon" />
                        </div>
                      </th>

                      <th id="dueDate">
                        <div
                          className="flex justify-between px-4 py-2 cursor-pointer w-max"
                          onClick={() => sortData(transactions, setTransactions, "date", "dueDate")}
                        >
                          <span>Due Date</span>
                          <img src={doubleArrow} alt="Sort Icon" />
                        </div>
                      </th>

                      <th id="amount">
                        <div
                          className="flex justify-between px-4 py-2 cursor-pointer text-nowrap w-max"
                          onClick={() => sortData(transactions, setTransactions, "number", "amount")}
                        >
                          <span>{user?.type === "Customer" ? "Credit Amount" : "Debit Amount"}</span>
                          <img src={doubleArrow} alt="Sort Icon" />
                        </div>
                      </th>

                      <th id="balance">
                        <div
                          className="flex justify-between px-4 py-2 cursor-pointer w-max"
                          onClick={() => sortData(transactions, setTransactions, "number", "balance")}
                        >
                          <span>Balance</span>
                          <img src={doubleArrow} alt="Sort Icon" />
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="ps-reg">
                    {transactions.length > 0 ?
                      transactions?.map(transaction => (
                        <tr key={transaction.ref} className="*:px-4 *:py-2 h-11">
                          <td headers="date">
                            <div className="w-max">{transaction.date}</div>
                          </td>
                          <td headers="ref">{transaction.ref}</td>
                          <td headers="description">
                            <div className="w-max">{
                              Array.isArray(transaction?.description) ?
                                transaction.description.map((desc, i) => <p key={i}>{desc}</p>) :
                                transaction.description
                            }
                            </div>
                          </td>
                          <td headers="dueDate">{transaction.dueDate}</td>
                          <td headers="amount">{transaction.amount}</td>
                          <td headers="balance">{transaction.balance}</td>
                        </tr>
                      ))
                      :
                      <tr>
                        <td colSpan={7} className="px-4 py-2 h-11">No transactions found the user in this range</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </section>






          {/* Table to print */}
          <section ref={printRef} className="p-5 print:block hidden space-y-8 relative">
            <div className="flex justify-between items-center">
              <img src={skLogo} alt="SK Printing Solution" className="h-10" />
              <h2>Ledger</h2>
              <p className="ps-bold">Date:  {formatDate(Date.now())}</p>
            </div>

            <div className="space-y-1 border-b pb-5">
              <h3>{userName}</h3>
              <div className="flex justify-between">
                <span><b>Email</b>: {`${user?.contact.email}`}</span>
                <span><b>Phone</b>: {`${user?.contact.phone}`}</span>
                <span><b>Address</b>: {`${user?.contact.address.street} ${user?.contact.address.city}, ${user?.contact.address.country}`}</span>
              </div>
            </div>

            <p>{formatDate(startDate)} - {formatDate(endDate)}</p>

            <table className="w-full text-xs">
              <thead>
                <tr className="*:p-2">
                  <th>Date</th>
                  <th>Ref #</th>
                  <th>Description</th>
                  <th>Due Date</th>
                  <th>{user?.type === "Customer" ? "Credit Amount" : "Debit Amount"}</th>
                  <th>Balance</th>
                </tr>
              </thead>

              <tbody className="ps-reg">
                {transactions?.map(transaction => (
                  <tr key={transaction.ref} className="*:p-2">
                    <td>{transaction.date}</td>
                    <td>{transaction.ref}</td>
                    <td>
                      <div className="w-max">{
                        Array.isArray(transaction?.description) ?
                          transaction.description.map((desc, i) => <p key={i}>{desc}</p>) :
                          transaction.description
                      }
                      </div>
                    </td>
                    <td>{transaction.dueDate}</td>
                    <td>{transaction.amount}</td>
                    <td>{transaction.balance}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="bg-[--primary-gray]">
              <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#838383] divide-x h-11">
                <h3 className="col-span-8 text-lg text-right">Total Amount</h3>
                <p className="col-span-2">Rs. {user?.balance?.totalPurchase}</p>
              </div>
              <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#838383] divide-x h-11 bg-white">
                <h3 className="col-span-8 text-lg text-right">Total Credit</h3>
                <p className="col-span-2">Rs. {user?.balance?.totalAmountPaid}</p>
              </div>
              <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#838383] divide-x h-11">
                <h3 className="col-span-8 text-lg text-right">Total Debit</h3>
                <p className="col-span-2">Rs. {user?.balance?.currentBalance}</p>
              </div>
            </div>
          </section>
        </div>
      </Draggable>



      {updateBalanceForm && <UpdateBalance isUserPage={true} onClose={closeForm} />}

      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
      {loader && <Loader />}
    </div>
  );
};