import { useEffect, useRef, useState } from "react";

import axios from "axios";
import ReactToPrint from 'react-to-print';

import { Navbar, Footer, UpdateBalance, Popup, Loader, ContentLoader, PurchaseDataForm, AddUser, Trash } from "../Components";
import { doubleArrow, search, print, skLogo } from "../assets";
import formatDate from "../utils/formatDate";
import throwError from "../utils/catch";
import sortData from "../utils/sortData";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";


export const Purchase = () => {
  const [purchases, setPurchases] = useState([]);
  const [printData, setPrintData] = useState(null);
  const purchaseRef = useRef();

  const [vendorForm, setVendorForm] = useState(false);
  const [purchaseDataForm, setPurchaseDataForm] = useState(false);
  const [updateBalanceForm, setUpdateBalanceForm] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);
  const [contentLoader, setContentLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [editingCell, setEditingCell] = useState({ purchaseId: null, field: null });
  const [editedValue, setEditedValue] = useState('');
  const [editedProducts, setEditedProducts] = useState([]);


  useEffect(() => getPurchases(), []);

  const getPurchases = (start, end) => {
    setContentLoader(true);

    let startDate, endDate;
    if (!start && !end) {
      // Calculating Last week date
      const oneWeek = 7 * 24 * 60 * 60 * 1000;
      startDate = new Date(Date.now() - oneWeek).toISOString();
      endDate = new Date(Date.now()).toISOString();
    } else {
      startDate = new Date(start).toISOString();
      endDate = new Date(end).toISOString();
    }

    axios.get(`${apiUrl}/purchases?startDate=${startDate}&endDate=${endDate}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then((res) => {
      setPurchases(res.data.purchases);
      console.log(res.data.message);
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setPurchases([]);
    }).finally(() => setContentLoader(false));
  };

  const handleDateFilter = (e) => {
    e.preventDefault();
    const startDate = e.target[0].value;
    const endDate = e.target[1].value;

    // Validating Start and End dates
    if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      setPopupData({
        isSuccess: false,
        message: "Start date must be before end date"
      });
      setShowPopup(true);
      return;
    }

    getPurchases(startDate, endDate);
  }

  const deletePurchase = (id) => {
    setLoader(true);

    axios.delete(`${apiUrl}/purchases/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then((res) => {
      console.log(res.data.message);
      const updatedPurchase = purchases.filter((purchase) => purchase._id !== id);
      setPurchases(updatedPurchase);
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    }).finally(() => setLoader(false));
  };

  const filteredPurchases = purchases ?
    purchases.filter((purchase) => {
      return purchase.products
        .map((product) => product?.product?.productName || "")
        .join(", ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    }) :
    [];

  const closeForm = (setForm) => {
    setForm(false);
    getPurchases();
  };

  const handlePrint = (purchase) => {
    return new Promise((resolve) => {
      setPrintData(purchase);
      setTimeout(resolve, 100);
    });
  };

  const handleSave = (purchaseId, field, value) => {
    // Check if the field is updated or not
    let updatedPurchase = purchases.find(purchase => purchase._id === purchaseId);

    // Checking purchase date
    if (field === "purchaseDate") {
      value = new Date(value);

      if (formatDate(updatedPurchase[field]) === formatDate(value)) {
        setEditingCell({ purchaseId: null, field: null });
        setEditedValue('');
        return;
      }
    }

    // Checking strings and numbers
    if (updatedPurchase[field] === value) {
      setEditingCell({ purchaseId: null, field: null });
      setEditedValue('');
      return;
    }

    if (field === "creditAmount" && isNaN(value)) {
      setShowPopup(true);
      setPopupData({
        isSuccess: false,
        message: "Please enter a valid number"
      });
      return;
    }

    if (field === "purchaseDate" && !value) {
      setShowPopup(true);
      setPopupData({
        isSuccess: false,
        message: "Please select a valid date"
      });
      return;
    }

    setLoader(true);

    axios.put(`${apiUrl}/purchases/${purchaseId}`, { [field]: value },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }).then((res) => {
        console.log(res.data.name + " : " + res.data.message);

        // Update the local purchases state
        setPurchases((prevPurchases) =>
          prevPurchases.map((purchase) =>
            purchase._id === purchaseId ? { ...purchase, [field]: value, debitAmount: res.data.purchase.debitAmount } : purchase
          )
        );

        // Reset editing state
        setEditingCell({ purchaseId: null, field: null });
        setEditedValue('');

        // Showing popup after updating
        setPopupData({
          isSuccess: true,
          message: res.data.message || "Purchase updated successfully"
        });
        setShowPopup(true);
      }).catch((err) => {
        console.error(err);
        throwError(err, setPopupData);

        // Optionally reset the editing state on error
        setPurchases((prevPurchases) => [...prevPurchases]);
        setEditingCell({ purchaseId: null, field: null });
        setEditedValue('');
      }).finally(() => setLoader(false));
  };

  const handleProductChange = (index, field, value) => {
    setEditedProducts((prevProducts) => {
      const newProducts = [...prevProducts];

      // Only allow changes to 'productQuantity' and 'totalCost'
      if (field === 'productQuantity' || field === 'totalCost') {
        newProducts[index][field] = value;
      }

      return newProducts;
    });
  };

  const handleSaveProducts = (purchaseId) => {
    // Validate the edited products before sending
    const valid = editedProducts.every((product) => {
      return (product.productQuantity > 0 && product.totalCost >= 0);
    });

    if (!valid) {
      setPopupData({
        isSuccess: false,
        message: "Please enter valid product quantities and total costs."
      });
      setShowPopup(true);
      return;
    }

    // Prepare data to send to backend
    const productsToSend = editedProducts.map((product) => ({
      product: typeof product.product === 'object' ? product.product._id : product.product,
      productQuantity: Number(product.productQuantity),
      totalCost: Number(product.totalCost)
    }));

    axios.put(`${apiUrl}/purchases/${purchaseId}`, { products: productsToSend }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      console.log(res.data.name + " : " + res.data.message);

      // Update local state
      setPurchases((prevPurchases) =>
        prevPurchases.map((purchase) =>
          purchase._id === purchaseId ? {
            ...purchase,
            products: res.data.purchase.products,
            totalAmount: res.data.purchase.totalAmount,
            creditAmount: res.data.purchase.creditAmount,
            debitAmount: res.data.purchase.debitAmount,
          } : purchase
        )
      );

      // Showing popup after updating
      setPopupData({
        isSuccess: true,
        message: res.data.message || "Products in the purchases updated successfully"
      });
      setShowPopup(true);

      // Reset editing state
      setEditingCell({ purchaseId: null, field: null });
      setEditedProducts([]);
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);

      // Optionally reset the editing state on error
      setPurchases((prevPurchases) => [...prevPurchases]);
      setEditingCell({ purchaseId: null, field: null });
      setEditedValue('');
    });
  };

  const handleCancelEdit = () => {
    setEditingCell({ purchaseId: null, field: null });
    setEditedProducts([]);
  };


  return (
    <>
      {/* Navbar */}
      <Navbar />



      <main className="px-[25px] py-4 space-y-4 lg:px-[60px] lg:py-8 center lg:space-y-8 flex-grow">


        {/* Cards */}
        <section className="grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-x-5">
          <div className="bg-[--primary-gray] lg:flex lg:flex-col max-lg:space-y-2.5 lg:gap-y-3 lg:items-center justify-center p-4 lg:h-[202px] rounded-[--radius-main]">
            <h2> Add Vendor </h2>
            <button className="btn btn-blue max-lg:w-full" onClick={() => setVendorForm(true)}>Add Vendor</button>
          </div>

          <div className="bg-[--primary-gray] lg:flex lg:flex-col max-lg:space-y-2.5 lg:gap-y-3 lg:items-center justify-center p-4 lg:h-[202px] rounded-[--radius-main]">
            <h2> Purchase Data Form </h2>
            <button className="btn btn-blue max-lg:w-full" onClick={() => setPurchaseDataForm(true)}> Add Purchase </button>
          </div>

          <div className="bg-[--primary-gray] lg:flex lg:flex-col max-lg:space-y-2.5 lg:gap-y-3 lg:items-center justify-center p-4 lg:h-[202px] rounded-[--radius-main]">
            <h2> Update Balance </h2>
            <button className="btn btn-blue max-lg:w-full" onClick={() => setUpdateBalanceForm(true)}> Update Balance </button>
          </div>
        </section>



        {/* Filter */}
        <form className="max-lg:space-y-5 lg:flex justify-between items-center max-lg:text-xs" onSubmit={handleDateFilter}>
          <div className="flex gap-x-2 lg:gap-x-5">
            <div className="flex flex-col gap-y-1 lg:gap-y-2 max-lg:w-full">
              <label htmlFor="startDate" className="font-bold">Start Date</label>
              <input type="date" name="startDate" id="startDate" required className="w-full" />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2 max-lg:w-full">
              <label htmlFor="endDate" className="font-bold">End Date</label>
              <input type="date" name="endDate" id="endDate" required className="w-full" />
            </div>
          </div>

          <button type="submit" className="btn btn-outline max-lg:w-full">Submit</button>
        </form>



        {/* Table */}
        <section>
          <div className="bg-[--btn-primary-blue] px-4 lg:px-10 py-2 rounded-t-[--radius-main]">
            <div className="relative flex justify-end">
              <input type="text" placeholder="Search Item" className="rounded-[22px] pl-reg lg:pr-10"
                value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}
              />
              <img src={search} alt="search" className="absolute right-4 top-1/2 -translate-y-1/2" />
            </div>
          </div>

          <div className="overflow-x-auto lg:max-h-[480px]">
            <table className="w-full">
              <thead>
                <tr>
                  <th id="purchaseId">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "string", "purchaseId")}
                    >
                      <span>Purchase ID</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="vendor">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "string", "vendor")}
                    >
                      <span>Vendor</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="item">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "array")}
                    >
                      <span>Item</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="extraCharges">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "number", "extraCharges")}
                    >
                      <span>Extra Charges</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="payMethod">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer text-nowrap w-max"
                      onClick={() => sortData(purchases, setPurchases, "string", "paymentMethod")}
                    >
                      <span>Payment Method</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="totalAmount">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "number", "totalAmount")}
                    >
                      <span>Total Amount</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="creditAmount">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer text-nowrap w-max"
                      onClick={() => sortData(purchases, setPurchases, "number", "creditAmount")}
                    >
                      <span>Debit Amount</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="debitAmount">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer text-nowrap w-max"
                      onClick={() => sortData(purchases, setPurchases, "number", "debitAmount")}
                    >
                      <span>Balance</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="purchaseDate">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "date", "purchaseDate")}
                    >
                      <span>Purchase Date</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="createdBy">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "string", "createdBy")}
                    >
                      <span>Created by</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="actions" />
                </tr>
              </thead>

              <tbody>
                {contentLoader ? (
                  <tr><ContentLoader columns={8} /></tr>
                ) : filteredPurchases.length > 0 ? (
                  filteredPurchases.map((purchase) => (
                    <tr key={purchase._id} className="*:px-4 *:py-2 h-11 ps-reg">

                      {/* Purchase ID */}
                      <td headers="purchaseId" title="Read-Only"> {purchase.purchaseId} </td>

                      {/* Vendor */}
                      <td headers="vendorName" title="Read-Only">
                        {`${purchase.vendor.name.firstName} ${purchase.vendor.name.lastName} - ${purchase.vendor.userId}`}
                      </td>

                      {/* Item */}
                      <td headers="item" title="Double-Click to Edit Qty and Rs"
                        onDoubleClick={() => {
                          setEditingCell({ purchaseId: purchase._id, field: 'products' });
                          setEditedProducts(purchase.products.map((product) => {
                            if (product.product) {
                              return {
                                ...product,
                                productId: product._id,
                                productName: product.product,
                              };
                            } else {
                              return {
                                ...product,
                                productId: product._id,
                                productName: product.product,
                              };
                            }
                          })
                          );
                        }}
                      >
                        {editingCell.purchaseId === purchase._id && editingCell.field === 'products' ? (
                          <div>
                            {editedProducts.map((product, index) => (
                              <div key={product._id || index} className="space-y-px">
                                {/* Product Name */}
                                <span className="font-semibold">{
                                  product.product ?
                                    `${product.product.productName} (${product.product.productCode})`
                                    : "Deleted Product"
                                }</span>

                                <div className="flex items-center gap-x-1">
                                  {/* Quantity */}
                                  <input
                                    type="number"
                                    value={product.productQuantity}
                                    onChange={(e) => handleProductChange(index, 'productQuantity', e.target.value)}
                                    min="1"
                                    placeholder="Quantity"
                                    className="border p-1"
                                    disabled={!product.product} // Here, we are disabling to edit if product is null
                                  />
                                  {/* Total Cost */}
                                  <input
                                    type="number"
                                    value={product.totalCost}
                                    onChange={(e) => handleProductChange(index, 'totalCost', e.target.value)}
                                    min="0"
                                    step="0.01"
                                    placeholder="Total Cost"
                                    className="border p-1"
                                    disabled={!product.product} // Here, we are disabling to edit if product is null
                                  />
                                </div>
                              </div>
                            ))}
                            {/* Add and Save Buttons */}
                            <div className="mt-2">
                              <button onClick={() => handleSaveProducts(purchase._id)} className="mr-2 p-1 bg-blue-500 text-white">
                                Save
                              </button>
                              <button onClick={handleCancelEdit} className="p-1 bg-gray-500 text-white">
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {purchase.products.map((el, index) => (
                              <div key={el._id || index} className="w-max">
                                {el.product ? (
                                  <>
                                    {el?.product?.productName} - {el?.productQuantity} qty - Rs. {el?.totalCost}
                                  </>
                                ) : (
                                  <>
                                    Deleted Product - {el.productQuantity} qty - Rs. {el.totalCost}
                                  </>
                                )}
                                {index < purchase.products.length - 1 && ', '}
                              </div>
                            ))}
                          </div>
                        )}
                      </td>


                      {/* Extra Charges */}
                      <td headers="extraCharges" title="Read-Only">{purchase.extraCharges}</td>


                      {/* Payment Method */}
                      <td headers="payMethod" title="Double-Click to Edit"
                        onDoubleClick={() => {
                          setEditingCell({ purchaseId: purchase._id, field: 'paymentMethod' });
                          setEditedValue(purchase.paymentMethod);
                        }}
                      >
                        {editingCell.purchaseId === purchase._id && editingCell.field === 'paymentMethod' ? (
                          <select
                            value={editedValue}
                            onChange={(e) => {
                              setEditedValue(e.target.value);
                              handleSave(purchase._id, 'paymentMethod', e.target.value);
                            }}
                            autoFocus
                          >
                            <option value={purchase.paymentMethod}>{purchase.paymentMethod}</option>
                            <option value={purchase.paymentMethod === "Cash" ? "Online" : "Cash"}>
                              {purchase.paymentMethod === "Cash" ? "Online" : "Cash"}
                            </option>
                          </select>
                        ) : (
                          purchase.paymentMethod
                        )}
                      </td>


                      {/* Total Amount */}
                      <td headers="totalAmount" title="Read-Only">{purchase.totalAmount}</td>


                      {/* Credit Amount */}
                      <td headers="creditAmount" title="Double-Click to Edit"
                        onDoubleClick={() => {
                          setEditingCell({ purchaseId: purchase._id, field: 'creditAmount' });
                          setEditedValue(purchase.creditAmount);
                        }}
                      >
                        {editingCell.purchaseId === purchase._id && editingCell.field === 'creditAmount' ? (
                          <input
                            type="number"
                            value={editedValue}
                            onChange={(e) => setEditedValue(e.target.value)}
                            onBlur={() => handleSave(purchase._id, 'creditAmount', parseFloat(editedValue))}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSave(purchase._id, 'creditAmount', parseFloat(editedValue));
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          purchase.creditAmount
                        )}
                      </td>

                      {/* Debit Amount */}
                      <td headers="debitAmount" title="Read-Only"> {purchase.debitAmount} </td>


                      {/* Purchase Date */}
                      <td headers="purchaseDate" title="Double-Click to Edit"
                        onDoubleClick={() => {
                          setEditingCell({ purchaseId: purchase._id, field: 'purchaseDate' });
                          setEditedValue(
                            purchase.purchaseDate ? new Date(purchase.purchaseDate).toISOString().split('T')[0] : ''
                          );
                        }}
                      >
                        {editingCell.purchaseId === purchase._id && editingCell.field === 'purchaseDate' ? (
                          <input
                            type="date"
                            value={editedValue}
                            onChange={(e) => setEditedValue(e.target.value)}
                            onBlur={() => handleSave(purchase._id, 'purchaseDate', editedValue)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSave(purchase._id, 'purchaseDate', editedValue);
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          formatDate(purchase.purchaseDate)
                        )}
                      </td>

                      <td headers="createdBy" title="Read-Only">{purchase?.createdBy?.username}</td>


                      <td headers="actions" className="flex gap-x-2 w-[88px] *:cursor-pointer">
                        <ReactToPrint
                          trigger={() => <img src={print} alt="Print button" />}
                          onBeforeGetContent={() => handlePrint(purchase)}
                          content={() => purchaseRef.current}
                        />
                        <div onClick={() => deletePurchase(purchase._id)}>
                          <Trash className="fill-[#3A3A3A]" />
                        </div>
                      </td>

                    </tr>

                  ))
                ) : (
                  <tr>
                    <td colSpan="9" className="text-[#E83535] font-bold p-4">
                      {popupData.message || "No purchase found"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </main>


      {/* Table to print */}
      <section ref={purchaseRef} className="p-10 print:block hidden space-y-8">
        <div className="flex justify-between items-center">
          <img src={skLogo} alt="SK Printing Solution" className="h-10" />
          <h2 className="text-center">Invoice</h2>
          <p className="ps-bold">Date: {formatDate(Date.now())}</p>
        </div>

        <div className="space-y-5">
          <div className="space-y-3">
            <div>
              <span className="pl-bold">Invoice # </span>
              <span>{printData?.purchaseId}</span>
            </div>
            <div>
              <span className="pl-bold">Vendor: </span>
              <span>{`${printData?.vendor.name.firstName} ${printData?.vendor.name.lastName}`}</span>
            </div>
            {printData?.orderDate &&
              <div>
                <span className="pl-bold">Order date: </span>
                <span>{formatDate(printData?.orderDate)}</span>
              </div>
            }
            <div>
              <span className="pl-bold">Purchase date: </span>
              <span>{formatDate(printData?.purchaseDate)}</span>
            </div>
            {printData?.paymentReceivedDate &&
              <div>
                <span className="pl-bold">Payment received date: </span>
                <span>{formatDate(printData?.paymentReceivedDate)}</span>
              </div>
            }
            <div>
              <span className="pl-bold">Payment method: </span>
              <span>{printData?.paymentMethod}</span>
            </div>
          </div>


          <div className="space-y-3">
            <h3>Details</h3>
            <table className="*:*:p-2 w-full border border-[#00000033]">
              <thead>
                <tr className="bg-[--primary-gray] divide-x divide-[#00000033]">
                  <th>Product Name</th>
                  <th>Price / unit</th>
                  <th>Quantity</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {printData?.products
                  ?.filter((purchase) => purchase.product)
                  ?.map((purchase, i) => (
                    <tr
                      key={purchase._id}
                      className={`${i % 2 !== 0 && "bg-[--primary-gray]"} divide-x divide-[#00000033]`}
                    >
                      <td>{purchase.product ? purchase.product.productName : "Deleted Product"}</td>
                      <td>
                        Rs. {purchase.costPrice}
                      </td>
                      <td>{purchase.productQuantity}</td>
                      <td>Rs. {purchase.totalCost}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {printData?.extraCharges > 0 &&
              <p>
                <b>Extra Charges</b>: Rs. {printData?.extraCharges}
              </p>
            }
          </div>

          <div className="space-y-3">
            <h3>Transactions</h3>
            <table className="*:*:p-2 w-full border border-[#00000033]">
              <thead>
                <tr className="bg-[--primary-gray] divide-x divide-[#00000033]">
                  <th>Transaction ID</th>
                  <th>Amount paid</th>
                  <th>Date</th>
                </tr>
              </thead>

              <tbody>
                {printData?.transactions?.map((transaction, i) => (
                  <tr key={transaction._id} className={`${i % 2 !== 0 && "bg-[--primary-gray]"} divide-x divide-[#00000033]`}>
                    <td>{transaction.transactionId}</td>
                    <td>Rs. {transaction.amount}</td>
                    <td>{formatDate(transaction.date)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="bg-[--primary-gray]">
            <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#00000033] divide-x">
              <h3 className="col-span-8 text-lg text-right">Total Amount</h3>
              <p className="col-span-2">Rs. {printData?.totalAmount}</p>
            </div>
            <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#00000033] divide-x bg-white">
              <h3 className="col-span-8 text-lg text-right">Credit Amount / Amount Paid</h3>
              <p className="col-span-2">Rs. {printData?.creditAmount}</p>
            </div>
            <div className="grid grid-cols-10 *:px-4 *:py-2 *:border-[#00000033] divide-x">
              <h3 className="col-span-8 text-lg text-right">Debit Amount / Balance</h3>
              <p className="col-span-2">Rs. {printData?.debitAmount}</p>
            </div>
          </div>

        </div>
      </section>

      {/* Footer */}
      <Footer />





      {/* Forms */}
      {vendorForm && <AddUser userType="Vendor" onClose={() => setVendorForm(false)} />}
      {purchaseDataForm && <PurchaseDataForm onClose={() => closeForm(setPurchaseDataForm)} />}
      {updateBalanceForm && <UpdateBalance isSalesPage={false} onClose={() => closeForm(setUpdateBalanceForm)} />}


      {/* Popup */}
      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
      {loader && <Loader />}
    </>
  );
};