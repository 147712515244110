import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Loader, Popup, AddAccount, ImageCropper, Trash } from "../Components";
import { logout as logoutIcon, account as accountIcon, pen } from "../assets";
import { useAuth } from "../context/AuthContext";
import axios from "axios";
import throwError from "../utils/catch";


const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";


export const ProfilePopup = ({ closePopup }) => {
  const { user, logout, updateProfilePicture } = useAuth();

  const fileInputRef = useRef();
  const [croppingImage, setCroppingImage] = useState({ image: null, mimeType: null });
  const [isCropActive, setIsCropActive] = useState(false);
  const [account, setAccount] = useState(null);

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);


  const handleFileChange = (e) => {
    if (e.target.files.length === 0 || e.target.files[0].type.split("/")[0] !== "image") {
      setPopupData({
        isSuccess: false,
        message: "Please select a valid image file."
      });
      setShowPopup(true);
      return;
    }

    setCroppingImage({
      image: URL.createObjectURL(e.target.files[0]),
      mimeType: e.target.files[0].type
    });
    setIsCropActive(true);
  };

  const onCropDone = (imageCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imageCroppedArea.width;
    canvasEle.height = imageCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let image = new Image();
    image.src = croppingImage.image;
    image.onload = () => {
      context.drawImage(
        image,
        imageCroppedArea.x,
        imageCroppedArea.y,
        imageCroppedArea.width,
        imageCroppedArea.height,
        0,
        0,
        imageCroppedArea.width,
        imageCroppedArea.height
      );

      // Convert canvas to Blob with dynamic type
      canvasEle.toBlob((blob) => {
        if (!blob) {
          console.error("Failed to convert to Blob");
          return;
        }

        // Create File from Blob
        const file = new File([blob], `profilePicture.${croppingImage.mimeType}`, { type: `${croppingImage.mimeType}` });

        // Prepare FormData
        const formData = new FormData();
        formData.append("image", file);

        // Send API request
        uploadCroppedImage(formData);
      }, `${croppingImage.mimeType}`);
    };
  };

  const uploadCroppedImage = (formData) => {
    setLoader(true);

    const payload = formData ? formData : { isDelete: true };

    axios.put(`${apiUrl}/accounts/${user.id}/profile-picture`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data"
      }
    }).then(res => {
      console.log(res.data.name + ": " + res.data.message);
      updateProfilePicture(res.data.image);
      setCroppingImage({ image: null, mimeType: null });
      setIsCropActive(false);
    }).catch(err => {
      console.error(err);
      setShowPopup(true);
      throwError(err, setPopupData);
    }).finally(() => setLoader(false));
  };

  const onCropCancel = () => {
    setIsCropActive(false);
    setCroppingImage({ image: null, mimeType: null });
  };


  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-20 z-10" onClick={() => closePopup()} />
      <div className={`space-y-4 p-4 lg:p-6 w-full lg:w-[360px] absolute top-[72px] lg:top-10 max-lg:left-0 lg:right-0 rounded-[--radius-main] bg-white z-20 ${account && "hidden"}`}>

        <div className="pb-4 border-b border-[#AFB3B8]">

          <div className="w-fit relative mx-auto group">
            {user?.image ?
              <img src={user?.image} alt="User profile" className="size-20 rounded-full" />
              : <h2 className="bg-[--btn-primary-blue] text-white size-20 rounded-full content-center text-center">{user?.name?.firstName?.at(0)}</h2>
            }
            <div className="absolute top-0 -right-7 bg-[#00000040] rounded-full p-2 flex gap-x-2 justify-center items-center lg:opacity-0 group-hover:opacity-100 transition-opacity *:cursor-pointer">
              <div onClick={() => fileInputRef.current.click()}> <img src={pen} alt="Edit" className="size-5" /> </div>
              <div onClick={() => uploadCroppedImage(null)}> <Trash className="fill-white size-5" /> </div>
            </div>

            {/* Hidden file input for image selection */}
            <input type="file" accept="image/*" ref={fileInputRef} className="hidden" onChange={handleFileChange} />
          </div>

          <div className="flex items-center justify-between mt-2">
            <div className="space-y-1">
              <h3>{`${user?.name?.firstName} ${user?.name?.lastName}`}</h3>
              <p className="ps-reg text-[--sec-gray]">@{user.username}</p>
            </div>
            <p className="py-1 px-2 bg-[--btn-primary-blue] text-white rounded-full ps-reg ">{user.role}</p>
          </div>

          <span
            className="text-[--btn-primary-blue] hover:text-[--btn-hover-primary-blue] font-semibold inline-block cursor-pointer mt-4"
            onClick={() => setAccount(user.id)}
          >
            Edit profile
          </span>
        </div>


        <div className="space-y-2">
          <Link to="/accounts" className="btn btn-outline flex items-center justify-center gap-x-2.5 w-full">
            <img src={accountIcon} alt="Accounts" />
            <span>Accounts</span>
          </Link>

          <Link to="/login" className="btn btn-blue flex items-center justify-center gap-x-2.5 w-full" onClick={logout}>
            <img src={logoutIcon} alt="Logout" />
            <span>Logout</span>
          </Link>
        </div>
      </div>





      {isCropActive && <ImageCropper image={croppingImage.image} onCropDone={onCropDone} onCropCancel={onCropCancel} />}

      {loader && <Loader />}
      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
      {account && <AddAccount onClose={() => setAccount(null)} accountId={account} />}
    </>
  );
};