import React from "react";

import { error, success } from "../assets";


export const Popup = ({ isSuccess, message, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 z-30">
      <div className="space-y-4 text-center p-6 rounded-[--radius-main] fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-[360px] bg-white">
        {isSuccess ? <img src={success} alt="success" className="mx-auto" /> : <img src={error} alt="error" className="mx-auto" />}

        <h3>{isSuccess ? "Success" : "Error"}</h3>

        <p>{message}</p>

        <button onClick={onClose} className={`btn btn-blue transition-colors ${isSuccess ? 'bg-[#009100] hover:bg-[#007800]' : 'bg-[#E83535] hover:bg-[#CF3030]'}`}>OK</button>
      </div>
    </div>
  );
};