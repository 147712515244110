import React, { useEffect, useState } from "react";
import axios from "axios";

import { Navbar, Footer, ContentLoader, Popup, ToggleButton, AddAccount } from "../Components";
import { doubleArrow, edit } from "../assets";

import throwError from "../utils/catch";
import sortData from "../utils/sortData";
import formatDate from "../utils/formatDate";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";


export const Accounts = () => {
    const [accounts, setAccounts] = useState([]);
    const [addAccountForm, setAccountForm] = useState(false);
    const [accountUpdate, setAccountUpdate] = useState(null);

    const [showPopup, setShowPopup] = useState(false);
    const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
    const [contentLoader, setContentLoader] = useState(false);

    const [filters, setFilters] = useState({ inputValue: "", inputType: "All" });
    const [searchFilters, setSearchFilters] = useState({ searchTerm: "", userRole: "All" });


    useEffect(() => getAccounts(), []);


    const getAccounts = () => {
        setContentLoader(true);

        axios.get(`${apiUrl}/accounts`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }).then((res) => {
            console.log(res.data.message);
            setAccounts(res.data.users);
        }).catch((err) => {
            console.error(err);
            throwError(err, setPopupData);
        }).finally(() => setContentLoader(false));
    };

    const toggleUserStatus = async (userId) => {
        axios.put(`${apiUrl}/accounts/${userId}/suspend`, null, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }).then((res) => {
            console.log(res.data.message);
            setAccounts((prevUsers) => prevUsers.map((user) => user._id === userId ? { ...user, isActive: res.data.user.isActive } : user));
        }).catch((err) => {
            console.error(err);
            setShowPopup(true);
            throwError(err, setPopupData);
        });
    };

    const editUser = (user) => {
        setAccountUpdate(user);
        setAccountForm(true);
    };

    const closeUserForm = () => {
        setAccountForm(false);
        setAccountUpdate(null);
        getAccounts();
    };

    const handleChange = (e) => {
        setFilters((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchFilters({
            searchTerm: filters.inputValue.trim(),
            userRole: filters.inputType,
        });
    };

    const handleClear = () => {
        setFilters({ inputValue: "", inputType: "All" });
        setSearchFilters({ searchTerm: "", userRole: "All" });
    };

    const filteredUsers = accounts ?
        accounts.filter((user) => {
            const firstName = user?.name?.firstName?.toLowerCase() || "";
            const lastName = user?.name?.lastName?.toLowerCase() || "";
            const fullName = `${firstName} ${lastName}`;

            // Split search input into separate words
            const searchWords = searchFilters.searchTerm.toLowerCase().split(" ");

            // Check if each search word exists in the full name
            const matchesName = searchFilters.searchTerm === "" || searchWords.every((word) => fullName.includes(word));

            const matchesType = searchFilters.userRole === "All" || user.role.toLowerCase() === searchFilters.userRole.toLowerCase();

            return matchesName && matchesType;
        }) :
        [];


    return (
        <>
            <Navbar />

            <main className="px-[25px] py-4 space-y-4 lg:px-[60px] lg:py-8 center lg:space-y-8 flex-grow">
                <section className="bg-[--primary-gray] lg:flex justify-between max-lg:space-y-2.5 lg:items-center p-5 rounded[--radius-main]">
                    <h2>Accounts</h2>
                    <button className="btn btn-blue max-lg:w-full" onClick={() => setAccountForm(true)}>Add Account</button>
                </section>

                {searchFilters.searchTerm && <h2>Search Result for {searchFilters.searchTerm}</h2>}

                <section>
                    <form className="bg-[--btn-primary-blue] px-4 lg:px-10 py-2 rounded-t-[--radius-main] max-lg:space-y-2 lg:flex justify-end gap-x-5" onSubmit={handleSearch}>

                        {/* Search Input */}
                        <div className="relative max-lg:w-full">
                            <input type="text" className="px-4 py-2 max-lg:w-full rounded" placeholder="Search by Name" name="inputValue" value={filters.inputValue} onChange={handleChange} />
                            {searchFilters.searchTerm &&
                                <span className="absolute top-2 right-2.5 size-5 text-center font-bold text-blue-900 cursor-pointer" onClick={handleClear}>x</span>
                            }
                        </div>

                        {/* User Type Dropdown */}
                        <div className="flex gap-x-2">
                            <label htmlFor="inputType" className="text-white font-semibold content-center max-lg:w-[103px]">User Type</label>
                            <select className="px-4 py-2 rounded max-lg:w-full" name="inputType" id="inputType" value={filters.inputType} onChange={handleChange}>
                                <option value="All">All</option>
                                <option value="Admin">Admin</option>
                                <option value="Data Analyst">Data Analyst</option>
                                <option value="CMS Admin">CMS Admin</option>
                                <option value="Salesman">Salesman</option>
                            </select>
                        </div>

                        {/* Search Button */}
                        <button type="submit" className="btn bg-white hover:bg-gray-200 text-[--btn-primary-blue] max-lg:w-full">Search</button>
                    </form>




                    <div className="overflow-x-auto lg:max-h-[480px]">
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th id="name">
                                        <div
                                            className="flex justify-between px-4 py-2 cursor-pointer w-max"
                                            onClick={() => sortData(accounts, setAccounts, "string", "name")}
                                        >
                                            <span>Name</span>
                                            <img src={doubleArrow} alt="Sort Icon" />
                                        </div>
                                    </th>

                                    <th id="username">
                                        <div
                                            className="flex justify-between px-4 py-2 cursor-pointer w-max"
                                            onClick={() => sortData(accounts, setAccounts, "string", "username")}
                                        >
                                            <span>Username</span>
                                            <img src={doubleArrow} alt="Sort Icon" />
                                        </div>
                                    </th>

                                    <th id="phone">
                                        <div
                                            className="flex justify-between px-4 py-2 cursor-pointer w-max"
                                            onClick={() => sortData(accounts, setAccounts, "string", "phone")}
                                        >
                                            <span>Phone No.</span>
                                            <img src={doubleArrow} alt="Sort Icon" />
                                        </div>
                                    </th>

                                    <th id="email">
                                        <div
                                            className="flex justify-between px-4 py-2 cursor-pointer w-max"
                                            onClick={() => sortData(accounts, setAccounts, "string", "email")}
                                        >
                                            <span>Email</span>
                                            <img src={doubleArrow} alt="Sort Icon" />
                                        </div>
                                    </th>

                                    <th id="address">
                                        <div
                                            className="flex justify-between px-4 py-2 cursor-pointer w-max"
                                            onClick={() => sortData(accounts, setAccounts, "string", "address")}
                                        >
                                            <span>Address</span>
                                            <img src={doubleArrow} alt="Sort Icon" />
                                        </div>
                                    </th>

                                    <th id="joiningDate">
                                        <div
                                            className="flex justify-between px-4 py-2 cursor-pointer w-max"
                                            onClick={() => sortData(accounts, setAccounts, "string", "joiningDate")}
                                        >
                                            <span>Joining date</span>
                                            <img src={doubleArrow} alt="Sort Icon" />
                                        </div>
                                    </th>

                                    <th id="role">
                                        <div
                                            className="flex justify-between px-4 py-2 cursor-pointer w-max"
                                            onClick={() => sortData(accounts, setAccounts, "string", "role")}
                                        >
                                            <span>User Role</span>
                                            <img src={doubleArrow} alt="Sort Icon" />
                                        </div>
                                    </th>
                                    <th id="actions" />
                                </tr>
                            </thead>

                            <tbody>
                                {contentLoader ? (
                                    <tr>
                                        <ContentLoader columns={7} />
                                    </tr>
                                ) : Array.isArray(filteredUsers) &&
                                    filteredUsers.length !== 0 ? (
                                    filteredUsers.map((user) => (
                                        <tr key={user?._id} className="*:px-4 *:py-2 h-11 ps-reg hover:bg-gray-300 cursor-pointer">
                                            <td headers="name">{`${user?.name?.firstName} ${user?.name.lastName}`}</td>
                                            <td headers="username">@{user.username}</td>
                                            <td headers="phone">{user?.phone}</td>
                                            <td headers="email">{user?.email}</td>
                                            <td headers="address">{user?.address}</td>
                                            <td headers="joiningDate">{formatDate(user?.joiningDate)}</td>
                                            <td headers="role">{user?.role}</td>
                                            <td headers="actions" className="flex gap-x-2 w-[100px]">
                                                <img src={edit} alt="Edit button" className="inline-block cursor-pointer" onClick={() => editUser(user)} />
                                                <ToggleButton handleToggle={() => toggleUserStatus(user?._id)} isActive={user?.isActive} />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="text-[#E83535] font-bold p-4">
                                            No Users Found.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </section>
            </main>



            <Footer />



            {/* Popup */}
            {addAccountForm && <AddAccount onClose={closeUserForm} userUpdate={accountUpdate} />}

            {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
        </>
    );
};