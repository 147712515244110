import React, { useState, useEffect } from "react";
import axios from "axios";

import Draggable from "react-draggable";

import { Navbar, Popup, Loader, Close } from "../Components";
import throwError from "../utils/catch";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";

export const AddUser = ({ onClose, userUpdate = null, userType = null }) => {
  const [user, setUser] = useState({
    name: { firstName: "", lastName: "" },
    contact: {
      phone: "",
      email: "",
      address: { street: "", city: "", country: "" },
    },
    type: userType || "Customer",
    registrationDate: ""
  });

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);

  const isSmallScreen = window.innerWidth < 1023;


  useEffect(() => {
    if (userUpdate) {
      setUser({
        _id: userUpdate._id || "",
        name: {
          firstName: userUpdate.name?.firstName || "",
          lastName: userUpdate.name?.lastName || "",
        },
        contact: {
          phone: userUpdate.contact?.phone || "",
          email: userUpdate.contact?.email || "",
          address: {
            street: userUpdate.contact?.address?.street || "",
            city: userUpdate.contact?.address?.city || "",
            country: userUpdate.contact?.address?.country || "",
          },
        },
        type: userUpdate.type || "Customer",
        registrationDate: userUpdate.registrationDate ? userUpdate.registrationDate.split("T")[0] : ""
      });
    }
  }, [userUpdate]);


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    const method = userUpdate ? "put" : "post";
    const url = userUpdate ? `${apiUrl}/users/${user._id}` : `${apiUrl}/users`;

    axios({
      method,
      url,
      data: user,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then((res) => {
      console.log(res.data);
      onClose();
    }).catch((err) => {
      console.error(err);
      setShowPopup(true);
      throwError(err, setPopupData);
    }).finally(() => setLoader(false));
  };

  const handleReset = () => {
    setUser({
      ...user,
      name: { firstName: "", lastName: "" },
      contact: {
        phone: "",
        email: "",
        address: { street: "", city: "", country: "" },
      },
      type: userType || "Customer",
      registrationDate: ""
    });
  };

  const closeForm = () => {
    handleReset();
    onClose();
  }


  return (
    <div className="lg:py-4 fixed top-0 left-0 z-10 h-dvh w-full grid place-items-center overflow-y-auto bg-black bg-opacity-60 !mt-0">
      <Draggable disabled={isSmallScreen} handle=".drag-handle">

        <form className="w-full lg:w-[670px] lg:rounded-[--radius-main] bg-white z-10 max-lg:h-dvh" onSubmit={handleSubmit}>
          <div className="lg:hidden"> <Navbar /> </div>

          <div className="drag-handle flex justify-between lg:bg-[--btn-primary-blue] lg:text-white px-6 lg:px-10 pt-4 lg:py-2 lg:rounded-t-[--radius-main]">
            <h3>{userUpdate ? `Update User - ${userUpdate?.userId}` : "New User"}</h3>
            <div onClick={closeForm} className="cursor-pointer hover:bg-[#ffffff0d] content-center">
              <Close fill="black" stroke="black" className="lg:hidden" />
              <Close fill="white" stroke="white" className="max-lg:hidden" />
            </div>
          </div>

          <div className="lg:max-h-[600px] overflow-y-auto max-lg:mt-4 px-6 pb-4 lg:p-10 grid grid-cols-1 lg:grid-cols-2 gap-y-3 lg:gap-y-6 gap-x-2 lg:gap-x-5 text-base font-semibold h-sm-form">
            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="firstName">First Name*</label>
              <input type="text" placeholder="John" name="firstName" id="firstName" required
                value={user.name.firstName} onChange={(e) => setUser({
                  ...user,
                  name: {
                    ...user.name,
                    firstName: e.target.value,
                  }
                })}
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="lastName">Last Name*</label>
              <input type="text" placeholder="Doe" name="lastName" id="lastName" required
                value={user.name.lastName} onChange={(e) => setUser({
                  ...user,
                  name: {
                    ...user.name,
                    lastName: e.target.value,
                  }
                })}
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="contactNo">Contact No.*</label>
              <input type="text" placeholder="+01 123 123 1234" name="phone" id="contactNo" required
                value={user.contact.phone} onChange={(e) => setUser({
                  ...user,
                  contact: {
                    ...user.contact,
                    phone: e.target.value,
                  }
                })}
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="email">Email*</label>
              <input type="text" placeholder="abc@email.com" name="email" id="email" required
                value={user.contact.email} onChange={(e) => setUser({
                  ...user,
                  contact: {
                    ...user.contact,
                    email: e.target.value,
                  }
                })}
              />
            </div>

            <div className={`flex flex-col gap-y-1 lg:gap-y-2 ${userUpdate && "lg:col-span-2"}`}>
              <label htmlFor="address">Address*</label>
              <input type="text" placeholder="H#654, Street" name="street" id="address" required
                value={user.contact.address.street}
                onChange={(e) => setUser((user) => ({
                  ...user,
                  contact: {
                    ...user.contact,
                    address: {
                      ...user.contact.address,
                      street: e.target.value,
                    },
                  }
                }))
                }
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="city">City*</label>
              <input type="text" placeholder="Karachi" name="city" id="city" required
                value={user.contact.address.city} onChange={(e) => setUser((user) => ({
                  ...user,
                  contact: {
                    ...user.contact,
                    address: {
                      ...user.contact.address,
                      city: e.target.value,
                    },
                  }
                }))
                }
              />
            </div>

            <div className="flex flex-col gap-y-1 lg:gap-y-2">
              <label htmlFor="country">Country*</label>
              <input type="text" placeholder="Pakistan" name="country" id="country" required
                value={user.contact.address.country} onChange={(e) => setUser((user) => ({
                  ...user,
                  contact: {
                    ...user.contact,
                    address: {
                      ...user.contact.address,
                      country: e.target.value,
                    },
                  }
                }))}
              />
            </div>

            {!userUpdate &&
              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="registrationDate">Registration Date</label>
                <input type="date" name="registrationDate" id="registrationDate"
                  value={user.registrationDate} onChange={(e) => setUser({
                    ...user,
                    registrationDate: e.target.value
                  })}
                />
              </div>
            }

            {(!userType && !userUpdate) &&
              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="userType">User Type*</label>
                <select name="type" id="type" value={user.type} onChange={(e) => setUser({
                  ...user,
                  type: e.target.value
                })}
                >
                  <option value="Customer">Customer</option>
                  <option value="Vendor">Vendor</option>
                </select>
              </div>
            }
          </div>

          <div className="px-6 py-8 lg:p-10 flex gap-x-3 lg:gap-x-5 border-t border-[#AFB3B8]">
            <button type="submit" className="btn btn-blue"> {userUpdate ? "Update" : "Add"} </button>
            <button onClick={handleReset} type="button" className="btn btn-outline"> Reset </button>
          </div>
        </form>
      </Draggable>


      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
      {loader && <Loader />}
    </div>
  );
};