import { useEffect, useState } from "react";

import Draggable from "react-draggable";
import axios from "axios";

import { Close, Loader, Navbar, Popup } from "../Components";
import { hide, show } from "../assets";
import throwError from "../utils/catch";


const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";


export const AddAccount = ({ onClose, userUpdate, accountId = null }) => {
    const [account, setAccount] = useState({
        name: { firstName: "", lastName: "" },
        phone: "",
        email: "",
        address: "",
        joiningDate: null,
        password: "",
        confirmPassword: "",
        role: "Admin"
    });
    const [createPasswordVisible, setCreatePasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const [showPopup, setShowPopup] = useState(false);
    const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
    const [loader, setLoader] = useState(false);

    const isSmallScreen = window.innerWidth < 1023;


    useEffect(() => {
        if (userUpdate) {
            setAccount({
                ...account,
                ...userUpdate,
                name: { firstName: userUpdate.name.firstName, lastName: userUpdate.name.lastName }
            });
        } else if (accountId) {
            axios.get(`${apiUrl}/accounts/${accountId}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            }).then((res) => {
                console.log(res.data.message);
                setAccount({
                    ...account,
                    ...res.data.user,
                    name: { firstName: res.data.user.name.firstName, lastName: res.data.user.name.lastName }
                });
            }).catch((err) => {
                console.error(err);
                setShowPopup(true);
                throwError(err, setPopupData);
            });
        }
    }, [account, userUpdate, accountId]);


    const handleSubmit = (e) => {
        e.preventDefault();
        let payload;

        if (!userUpdate && !accountId) {
            // Validating password contains combination of letters, numbers, and special symbols.
            const hasSpecialSymbol = /[^A-Za-z0-9]/;
            const hasNumber = /\d/;
            if (!hasSpecialSymbol.test(account.password) && !hasNumber.test(account.password)) {
                setShowPopup(true);
                setPopupData({
                    message: "Password must have letters, numbers and special symbols",
                    isSuccess: false
                });
                return;
            }

            // Checking if the passwords are same or not
            if (account.password !== account.confirmPassword) {
                setPopupData({
                    message: "Passwords do not match",
                    isSuccess: false
                });
                setShowPopup(true);
                return;
            }

            let { confirmPassword, ...obj } = account;
            payload = obj;
        } else {

            payload = {
                name: {
                    firstName: account.name.firstName,
                    lastName: account.name.lastName
                },
                phone: account.phone,
                address: account.address,
            };

            if (userUpdate) {
                payload.email = account.email;
                payload.role = account.role;
            }
        }

        const method = (userUpdate || accountId) ? "put" : "post";
        const url = accountId ? `${apiUrl}/accounts/${accountId}/update-profile` : userUpdate ? `${apiUrl}/accounts/${account._id}` : `${apiUrl}/accounts`;

        setLoader(true);
        axios({
            method,
            url,
            data: payload,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        }).then((res) => {
            console.log(res.data);
            setPopupData({ isSuccess: true, message: res.data?.message });
            setShowPopup(true);
        }).catch((err) => {
            console.error(err);
            setShowPopup(true);
            throwError(err, setPopupData)
        }).finally(() => {
            setLoader(false);
        });
    };

    const handleReset = () => {
        setAccount({
            ...account,
            name: { firstName: "", lastName: "" },
            phone: "",
            email: "",
            address: "",
            joiningDate: null,
            password: "",
            confirmPassword: "",
            role: "Admin"
        });
    }

    const closePopup = () => {
        setShowPopup(false);
        if (popupData.isSuccess) onClose();
    };


    return (
        <div className="lg:py-4 fixed top-0 left-0 z-10 h-dvh w-full grid place-items-center overflow-y-auto bg-black bg-opacity-60 !mt-0">
            <Draggable disabled={isSmallScreen} handle=".drag-handle">

                <form className="w-full lg:w-[670px] lg:rounded-[--radius-main] bg-white z-10 max-lg:h-dvh" onSubmit={handleSubmit}>
                    <div className="lg:hidden"> <Navbar /> </div>

                    <div className="drag-handle flex justify-between lg:bg-[--btn-primary-blue] lg:text-white px-6 lg:px-10 pt-4 lg:py-2 lg:rounded-t-[--radius-main]">
                        <h3>{(userUpdate || accountId) ? `Update Account - @${account?.username}` : "New Account"}</h3>
                        <div onClick={onClose} className="cursor-pointer hover:bg-[#ffffff0d] content-center">
                            <Close fill="black" stroke="black" className="lg:hidden" />
                            <Close fill="white" stroke="white" className="max-lg:hidden" />
                        </div>
                    </div>

                    <div className="lg:max-h-[600px] overflow-y-auto max-lg:mt-4 px-6 pb-4 lg:p-10 grid grid-cols-1 lg:grid-cols-2 gap-y-3 lg:gap-y-6 gap-x-2 lg:gap-x-5 text-base font-semibold h-sm-form">
                        <div className="flex flex-col gap-y-1 lg:gap-y-2">
                            <label htmlFor="firstName">First Name*</label>
                            <input type="text" placeholder="John" name="firstName" id="firstName" required
                                value={account.name.firstName} onChange={(e) => setAccount({
                                    ...account,
                                    name: {
                                        ...account.name,
                                        firstName: e.target.value,
                                    }
                                })}
                            />
                        </div>

                        <div className="flex flex-col gap-y-1 lg:gap-y-2">
                            <label htmlFor="lastName">Last Name*</label>
                            <input type="text" placeholder="Doe" name="lastName" id="lastName" required
                                value={account.name.lastName} onChange={(e) => setAccount({
                                    ...account,
                                    name: {
                                        ...account.name,
                                        lastName: e.target.value,
                                    }
                                })}
                            />
                        </div>

                        <div className="flex flex-col gap-y-1 lg:gap-y-2">
                            <label htmlFor="contactNo">Phone No.*</label>
                            <input type="text" placeholder="+01 123 123 1234" name="phone" id="contactNo" required
                                value={account.phone} onChange={(e) => setAccount({ ...account, phone: e.target.value })}
                            />
                        </div>

                        {!accountId && <div className="flex flex-col gap-y-1 lg:gap-y-2">
                            <label htmlFor="email">Email*</label>
                            <input type="text" placeholder="abc@email.com" name="email" id="email" required
                                value={account.email} onChange={(e) => setAccount({ ...account, email: e.target.value })}
                            />
                        </div>}

                        <div className={`flex flex-col gap-y-1 lg:gap-y-2 ${(!userUpdate && !accountId) && "lg:col-span-2"}`}>
                            <label htmlFor="address">Address*</label>
                            <input type="text" placeholder="H#654, Street" name="street" id="address" required
                                value={account.address}
                                onChange={(e) => setAccount((user) => ({ ...user, address: e.target.value }))
                                }
                            />
                        </div>

                        {(!userUpdate && !accountId) && <div className="flex flex-col gap-y-1 lg:gap-y-2">
                            <label htmlFor="joiningDate">Joining Date</label>
                            <input type="date" name="joiningDate" id="joiningDate"
                                value={account.joiningDate} onChange={(e) => setAccount({ ...account, joiningDate: e.target.value })}
                            />
                        </div>}

                        {(!userUpdate && !accountId) && <div className="flex flex-col gap-y-1 lg:gap-y-2">
                            <label htmlFor="password">Create Password</label>
                            <div className="relative">
                                <input type={createPasswordVisible ? "text" : "password"} name="password" id="password"
                                    className="pl-4 pr-10 py-2 w-full" minLength="6" required placeholder="apple***"
                                    value={account.password} onChange={(e) => setAccount({ ...account, password: e.target.value })}

                                />

                                <div className="absolute inset-y-0 right-3 flex items-center py-1 cursor-pointer">
                                    <img src={createPasswordVisible ? show : hide} alt="toggle visibility"
                                        className="size-6" onClick={() => setCreatePasswordVisible(!createPasswordVisible)}
                                    />
                                </div>
                            </div>
                        </div>}

                        {(!userUpdate && !accountId) && <div className="flex flex-col gap-y-1 lg:gap-y-2">
                            <label htmlFor="confirmPassword">Confirm password</label>
                            <div className="relative">
                                <input type={confirmPasswordVisible ? "text" : "password"} name="confirmPassword" id="confirmPassword"
                                    className="pl-4 pr-10 py-2 w-full" minLength="6" required placeholder="apple***"
                                    value={account.confirmPassword} onChange={(e) => setAccount({ ...account, confirmPassword: e.target.value })}

                                />

                                <div className="absolute inset-y-0 right-3 flex items-center py-1 cursor-pointer">
                                    <img src={confirmPasswordVisible ? show : hide} alt="toggle visibility"
                                        className="size-6" onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                    />
                                </div>
                            </div>
                        </div>}

                        {!accountId && <div className="flex flex-col gap-y-1 lg:gap-y-2">
                            <label htmlFor="userType">User Role*</label>
                            <select name="type" id="type" value={account.role} onChange={(e) => setAccount({ ...account, role: e.target.value })}
                            >
                                <option value="Admin">Admin</option>
                                <option value="Data Analyst">Data Analyst</option>
                                <option value="CMS Admin">CMS Admin</option>
                                <option value="Salesman">Salesman</option>
                            </select>
                        </div>}
                    </div>

                    <div className="px-6 py-8 lg:p-10 flex gap-x-3 lg:gap-x-5 border-t border-[#AFB3B8]">
                        <button type="submit" className="btn btn-blue"> {(userUpdate || accountId) ? "Update" : "Add"} </button>
                        <button onClick={handleReset} type="button" className="btn btn-outline"> Reset </button>
                    </div>
                </form>

            </Draggable>

            {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={closePopup} />}
            {loader && <Loader />}
        </div>
    )
}