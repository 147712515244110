import React, { useEffect, useState } from "react";
import axios from "axios";

import { AddProductPopup, Navbar, Popup, Footer, Trash } from "../Components";

import { search, doubleArrow, edit } from "../assets";
import throwError from "../utils/catch";
import sortData from "../utils/sortData";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";


export const Products = () => {
  const [addProductForm, setAddProductForm] = useState(false);
  const [products, setProducts] = useState([]);

  const [showPopup, setshowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });


  useEffect(() => getProducts(), []);

  const getProducts = () => {
    axios.get(`${apiUrl}/api/product/getAll`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then((res) => {
      setProducts(res.data.products);
      console.log(res.data.message);
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setProducts([]);
    });
  };

  const deleteProduct = (id) => {
    axios.delete(`${apiUrl}/api/product/delete/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then((res) => {
      console.log(res.data);
      getProducts();
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setshowPopup(true);
    });
  };


  return (
    <>
      {/* Navbar */}
      <Navbar />



      {/* Main */}
      <main className="px-[25px] py-4 space-y-4 lg:px-[60px] lg:py-8 center lg:space-y-8">
        <section className="bg-[--primary-gray] lg:flex justify-between max-lg:space-y-2.5 lg:items-center p-5 rounded[--radius-main]">
          <h2 className="max-lg:text-base">Product Form</h2>
          <button onClick={() => setAddProductForm(true)} className="btn btn-blue max-lg:w-full">Add Product</button>
          {addProductForm && <AddProductPopup />}
        </section>

        <section>
          <div className="bg-[--btn-primary-blue] px-4 lg:px-10 py-2 rounded-t-[--radius-main]">
            <div className="relative flex justify-end">
              <input
                type="text"
                placeholder="Search Product"
                className="rounded-[22px] pl-reg lg:pr-10"
              />

              <img
                src={search}
                alt="search"
                className="absolute right-4 top-1/2 -translate-y-1/2"
              />
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-max">
              <thead>
                <tr>
                  <th id="productCode">
                    <div
                      className="flex justify-between px-4 py-2 w-40 cursor-pointer max-lg:w-max"
                      onClick={() => sortData(products, setProducts, "string", "productCode")}
                    >
                      <span>productCode</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="name">
                    <div
                      className="flex justify-between px-4 py-2 w-40 cursor-pointer max-lg:w-max"
                      onClick={() => sortData(products, setProducts, "string", "name")}
                    >
                      <span>Name</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="category">
                    <div
                      className="flex justify-between px-4 py-2 w-40 cursor-pointer max-lg:w-max"
                      onClick={() => sortData(products, setProducts, "string", "category")}
                    >
                      <span>Category</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="images">
                    <div className="flex justify-between px-4 py-2 w-60 cursor-pointer max-lg:w-max">
                      <span>Images</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="description">
                    <div
                      className="flex justify-between px-4 py-2 w-[280px] cursor-pointer max-lg:w-max"
                      onClick={() => sortData(products, setProducts, "string", "description")}
                    >
                      <span>Description</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="brand">
                    <div
                      className="flex justify-between px-4 py-2 w-36 cursor-pointer max-lg:w-max"
                      onClick={() => sortData(products, setProducts, "string", "brand")}
                    >
                      <span>Brand</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="model">
                    <div
                      className="flex justify-between px-4 py-2 w-36 cursor-pointer max-lg:w-max"
                      onClick={() => sortData(products, setProducts, "string", "modelNo")}
                    >
                      <span>Model No</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="details">
                    <div
                      className="flex justify-between px-4 py-2 w-[400px] cursor-pointer max-lg:w-max"
                      onClick={() => sortData(products, setProducts, "string", "details")}
                    >
                      <span>Details</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="quantity">
                    <div
                      className="flex justify-between px-4 py-2 w-36 cursor-pointer max-lg:w-max"
                      onClick={() => sortData(products, setProducts, "number", "quantity")}
                    >
                      <span>Quantity</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="dimension">
                    <div
                      className="flex justify-between px-4 py-2 w-36 cursor-pointer max-lg:w-max"
                      onClick={() => sortData(products, setProducts, "string", "dimension")}
                    >
                      <span>Dimension</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="price">
                    <div
                      className="flex justify-between px-4 py-2 w-36 cursor-pointer max-lg:w-max"
                      onClick={() => sortData(products, setProducts, "number", "price")}
                    >
                      <span>Price</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="actions" />
                </tr>
              </thead>

              <tbody>
                {products.length > 0 ? (
                  products.map((product) => (
                    <tr
                      key={product?._id}
                      className="*:px-4 *:py-2 h-[76px] ps-reg"
                    >
                      <td headers="productCode" className="w-40">
                        {product?.productCode}
                      </td>
                      <td headers="name" className="w-40">
                        {product?.name}
                      </td>
                      <td headers="category" className="w-40">
                        {product?.category}
                      </td>
                      <td
                        headers="images"
                        className="w-[228px] grid grid-cols-3 gap-2"
                      >
                        {product?.images?.map((image, i) => (
                          <div
                            key={i}
                            className="size-[60px] border border-black inline-block"
                          >
                            {image}
                          </div>
                        ))}
                      </td>
                      <td headers="description" className="w-[280px]">
                        {product?.description}
                      </td>
                      <td headers="brand" className="w-36">
                        {product?.brand}
                      </td>
                      <td headers="model" className="w-36">
                        {product?.modelNo}
                      </td>
                      <td headers="details" className="w-[400px]">
                        {product?.details}
                      </td>
                      <td headers="quantity" className="w-36">
                        {product?.quantity || "--"}
                      </td>
                      <td headers="dimension" className="w-36">
                        {product?.dimension || "--"}
                      </td>
                      <td headers="price" className="w-36">
                        {product?.price}
                      </td>
                      <td headers="actions" className="flex gap-x-2 w-[88px] *:cursor-pointer">
                        <img src={edit} alt="Edit button" />
                        <div onClick={() => deleteProduct(product?._id)}>
                          <Trash className="fill-[#3A3A3A]" />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="11" className="text-[#E83535] font-bold p-4">
                      {popupData.message}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </main>

      {/* Footer */}
      <Footer />

      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setshowPopup(false)} />}
    </>
  );
};
