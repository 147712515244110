import { createContext, useContext, useState, useEffect } from 'react';

import axios from "axios";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    // Added loading state
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const savedUser = JSON.parse(localStorage.getItem('user'));
        if (savedUser && savedUser.token) {
            axios.post(`${apiUrl}/auth/verify-token`, { userId: savedUser.id }, {
                headers: { 'Authorization': `Bearer ${savedUser.token}` }
            }).then((res) => {
                if (res.data.valid) {
                    setUser(savedUser);
                } else {
                    setUser(null);
                    localStorage.removeItem('user');
                }
                setLoading(false);
            }).catch((error) => {
                console.error("Token verification failed", error);
                setUser(null);
                setLoading(false);
            });
        } else {
            setUser(null);
            setLoading(false);
        }
    }, []);

    const login = (userData) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem("token", userData.token);
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem("token");
    };


    const updateProfilePicture = (image) => {
        setUser({ ...user, image });
        localStorage.setItem('user', JSON.stringify({ ...user, image }));
    }

    return (
        <AuthContext.Provider value={{ user, updateProfilePicture, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
}