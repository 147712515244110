export const Close = ({ fill, stroke, className }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g clipPath="url(#clip0_841_8679)">
                <path d="M6.804 0.5H17.208C19.1205 0.5 20.6828 1.16854 21.768 2.3044C22.8551 3.44229 23.5 5.08778 23.5 7.104V16.908C23.5 18.9179 22.8554 20.5603 21.7682 21.6969C20.6829 22.8314 19.1205 23.5 17.208 23.5H6.804C4.89137 23.5 3.32576 22.8314 2.23738 21.6964C1.14725 20.5597 0.5 18.9173 0.5 16.908V7.104C0.5 5.08838 1.14747 3.44297 2.23758 2.30486C3.32591 1.16862 4.89139 0.5 6.804 0.5Z" stroke={stroke} />
                <path d="M17.6025 15.3236L14.3411 12.0641L17.6006 8.80459C18.2276 8.17945 18.2276 7.16199 17.6006 6.53685C16.9737 5.90622 15.9599 5.90805 15.3329 6.53502L12.0715 9.79454L8.8102 6.53135C8.18322 5.90438 7.1676 5.90805 6.54063 6.53135C5.91549 7.15833 5.91549 8.17578 6.54063 8.80092L9.80382 12.0641L6.54796 15.3181C5.92099 15.9451 5.92099 16.9626 6.54796 17.5859C6.86145 17.9012 7.27027 18.057 7.68091 18.057C8.09339 18.057 8.50221 17.9012 8.8157 17.5877L12.0715 14.3318L15.3347 17.5932C15.6482 17.9067 16.057 18.0625 16.4677 18.0625C16.8783 18.0625 17.289 17.9048 17.6025 17.5932C18.2294 16.9662 18.2294 15.9506 17.6025 15.3236Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_841_8679">
                    <rect width="24" height="24" />
                </clipPath>
            </defs>
        </svg>
    );
};


export const Trash = ({ className }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" className={className} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.3846 8.72019C19.7976 8.75419 20.1056 9.11519 20.0726 9.52819C20.0666 9.59619 19.5246 16.3072 19.2126 19.1222C19.0186 20.8692 17.8646 21.9322 16.1226 21.9642C14.7896 21.9872 13.5036 22.0002 12.2466 22.0002C10.8916 22.0002 9.5706 21.9852 8.2636 21.9582C6.5916 21.9252 5.4346 20.8412 5.2456 19.1292C4.9306 16.2892 4.3916 9.59519 4.3866 9.52819C4.3526 9.11519 4.6606 8.75319 5.0736 8.72019C5.4806 8.70919 5.8486 8.99519 5.8816 9.40719C5.88479 9.45059 6.10514 12.1842 6.34526 14.8889L6.39349 15.4286C6.51443 16.773 6.63703 18.0648 6.7366 18.9642C6.8436 19.9372 7.3686 20.4392 8.2946 20.4582C10.7946 20.5112 13.3456 20.5142 16.0956 20.4642C17.0796 20.4452 17.6116 19.9532 17.7216 18.9572C18.0316 16.1632 18.5716 9.47519 18.5776 9.40719C18.6106 8.99519 18.9756 8.70719 19.3846 8.72019ZM14.3454 2.00049C15.2634 2.00049 16.0704 2.61949 16.3074 3.50649L16.5614 4.76749C16.6435 5.18086 17.0062 5.48275 17.4263 5.48938L20.708 5.48949C21.122 5.48949 21.458 5.82549 21.458 6.23949C21.458 6.65349 21.122 6.98949 20.708 6.98949L17.4556 6.98934C17.4505 6.98944 17.4455 6.98949 17.4404 6.98949L17.416 6.98849L7.04162 6.98937C7.03355 6.98945 7.02548 6.98949 7.0174 6.98949L7.002 6.98849L3.75 6.98949C3.336 6.98949 3 6.65349 3 6.23949C3 5.82549 3.336 5.48949 3.75 5.48949L7.031 5.48849L7.13202 5.48209C7.50831 5.43327 7.82104 5.14749 7.8974 4.76749L8.1404 3.55149C8.3874 2.61949 9.1944 2.00049 10.1124 2.00049H14.3454ZM14.3454 3.50049H10.1124C9.8724 3.50049 9.6614 3.66149 9.6004 3.89249L9.3674 5.06249C9.33779 5.21068 9.29467 5.3535 9.23948 5.48976H15.2186C15.1634 5.3535 15.1201 5.21068 15.0904 5.06249L14.8474 3.84649C14.7964 3.66149 14.5854 3.50049 14.3454 3.50049Z" />
        </svg>
    )
}
