import React, { useEffect, useState } from "react";
import axios from "axios";

import { Navbar, Footer, InventoryForm, Popup, Loader, ContentLoader, Trash } from "../Components";
import { doubleArrow, search, edit } from "../assets";
import throwError from "../utils/catch";
import sortData from "../utils/sortData";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";


export const Inventory = () => {
  const [products, setProducts] = useState([]);
  const [inventoryForm, setInventoryForm] = useState(false);
  const [productToUpdate, setProductToUpdate] = useState(null);

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);
  const [contentLoader, setContentLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");


  useEffect(() => getInventoryProducts(), []);


  const getInventoryProducts = () => {
    setContentLoader(true);

    axios.get(`${apiUrl}/inventory`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then((res) => {
      setProducts(res.data.inventory);
      console.log(res.data.message);
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setProducts([]);
    }).finally(() => setContentLoader(false));
  };

  const deleteInventory = (id) => {
    setLoader(true);

    axios.put(`${apiUrl}/inventory/delete/${id}`, {}, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then((res) => {
      console.log(res.data);

      const udpatedInventory = products.filter((product) => product._id !== id);
      setProducts(udpatedInventory);
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    }).finally(() => setLoader(false));
  };

  const editInventory = (product) => {
    setProductToUpdate(product);
    setInventoryForm(true);
  };

  const closeForm = () => {
    setInventoryForm(false);
    setProductToUpdate(null);
    getInventoryProducts();
  };

  const filteredProducts = products ? products.filter((product) => product.productName.toLowerCase().includes(searchTerm.toLowerCase())) : [];


  return (
    <>
      {/* Navbar */}
      <Navbar />



      {/* Main */}
      <main className="px-[25px] py-4 space-y-4 lg:px-[60px] lg:py-8 center lg:space-y-8 flex-grow">


        <section className="bg-[--primary-gray] lg:flex justify-between max-lg:space-y-2.5 lg:items-center p-5 rounded-[--radius-main]">
          <h2>Inventory Form</h2>
          <button onClick={() => setInventoryForm(!inventoryForm)} className="btn btn-blue w-full lg:w-[139px]"> Add Entry </button>
        </section>




        {/* Table */}
        <section>
          <div className="bg-[--btn-primary-blue] px-4 lg:px-10 py-2 rounded-t-[--radius-main]">
            <div className="relative flex justify-end">
              <input className="rounded-[22px] pl-reg lg:pr-10" type="text" placeholder="Search Product"
                value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
              <img src={search} alt="search" className="absolute right-4 top-1/2 -translate-y-1/2" />
            </div>
          </div>

          <div className="overflow-x-auto lg:max-h-[480px]">
            <table className="w-full">
              <thead>
                <tr>
                  <th id="productCode">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(products, setProducts, "string", "productCode")}
                    >
                      <span>Product Code</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="productName">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(products, setProducts, "string", "productName")}
                    >
                      <span>Product Name</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="qty">
                    <div
                      className="flex text-nowrap justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(products, setProducts, "number", "stock")}
                    >
                      <span>Quantity in Stock</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="category">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(products, setProducts, "string", "category")}
                    >
                      <span>Category</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="costPrice">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(products, setProducts, "number", "costPrice")}
                    >
                      <span>Cost Price</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="sellingPrice">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(products, setProducts, "number", "sellingPrice")}
                    >
                      <span>Selling Price</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="inventoryManager">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(products, setProducts, "string", "inventoryManager")}
                    >
                      <span>Inventory Manager</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="actions" />
                </tr>
              </thead>

              <tbody>
                {contentLoader ? (
                  <tr><ContentLoader columns={7} /></tr>
                ) : filteredProducts.length !== 0 ? (
                  filteredProducts.map((product) => (
                    <tr
                      key={product?._id}
                      className="*:px-4 *:py-2 h-11 ps-reg"
                    >
                      <td headers="productCode">{product?.productCode}</td>

                      <td headers="productName">{product?.productName}</td>

                      <td headers="qty">{product?.stock}</td>

                      <td headers="category">{product?.category}</td>

                      <td headers="costPrice">{product?.costPrice}</td>

                      <td headers="sellingPrice">{product?.sellingPrice}</td>

                      <td headers="inventoryManager">{product?.inventoryManager?.username}</td>

                      <td headers="actions" className="flex gap-x-2 w-[88px] *:cursor-pointer">
                        <img src={edit} alt="Edit button" onClick={() => editInventory(product)} />
                        <div onClick={() => deleteInventory(product?._id)}>
                          <Trash className="fill-[#3A3A3A]" />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-[#E83535] font-bold p-4"> {popupData.message} </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </main>



      {/* Footer */}
      <Footer />



      {/* Form */}
      {inventoryForm && <InventoryForm onClose={() => closeForm()} productToUpdate={productToUpdate} />}

      {/* Popup */}
      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
      {loader && <Loader />}
    </>
  );
};
